import { v4 as uuidv4 } from "uuid";
import { actionType } from "./index";

const initialState: any[] = [];

export default (state = initialState, { type, payload }: actionType) => {
  switch (type) {
    case "ADD_ALERT":
      return [...state, { ...payload, id: uuidv4() }];
    case "REMOVE_ALERT":
      return state.filter((f) => f.id !== payload);
    default:
      return state;
  }
};
