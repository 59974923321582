import { PermissionWrapper } from "@neostella/neostella-ui";
import { Route, Switch } from "react-router-dom";
import SurveyTemplates from "./SurveyTemplates";
import SurveyTemplateCreate from "./SurveyTemplatesCreate";
import SurveyTemplatesEdit from "./SurveyTemplatesEdit";

export default function SurveyTemplateRouter() {
  return (
    <Switch>
      <Route path="/admin/survey_templates/create">
        <PermissionWrapper
          permissionKeys={["admin.survey_template.general.can_read"]}
          type="hide"
          redirect="/admin"
        >
          <SurveyTemplateCreate />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/survey_templates/:id">
        <PermissionWrapper
          permissionKeys={["admin.survey_template.general.can_read"]}
          type="hide"
          redirect="/admin"
        >
          <SurveyTemplatesEdit />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/survey_templates">
        <PermissionWrapper
          permissionKeys={["admin.survey_template.general.can_read"]}
          type="hide"
          redirect="/admin"
        >
          <SurveyTemplates />
        </PermissionWrapper>
      </Route>
    </Switch>
  );
}
