import { useEffect, useState } from "react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";

import { axiosInstance, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { SurveyCreator } from "survey-creator-react";
import usePresignedUrls from "../../../../hooks/usePresignedUrls";
import { SurveysPostResponse } from "./AccountSurveysCreateFromScratch";
import { SurveyByIdResp, SurveyData } from "./AccountSurveysCreateFromTemplate";
import CreateSurveyComponent, {
  PostPayload,
  PutPayload,
} from "./CreateSurveyComponent";

export default function AccountSurveysEditSurvey() {
  const { surveyId, id }: any = useParams();
  const [surveyData, setSurveyData] = useState<SurveyData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [readOnly, setReadOnly] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const { getPresignedUrl, fetchSurveyData, saveSurveyData, isSurveyValid } =
    usePresignedUrls();

  const getSurveyData = async () => {
    const { data: res } = await axiosInstance.get<SurveyByIdResp>(
      `surveys/${surveyId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      }
    );

    const presignedUrlGET = await getPresignedUrl(
      "get_object",
      `surveys/${surveyId}.json`
    );

    const surveyDataJson = await fetchSurveyData<SurveyData>(
      presignedUrlGET.presigned_url.url
    );

    setSurveyData(surveyDataJson);
    setReadOnly(res.survey[0].status_type_name !== "draft");
  };

  const editSurvey = async (
    creator: SurveyCreator,
    isTemplate: boolean = false
  ) => {
    setIsSubmitting(true);
    const surveyJSON = creator.JSON;
    setSurveyData(surveyJSON);
    const putPayload: PutPayload = {
      survey_id: surveyId,
      name: surveyJSON.title,
      // survey_data: surveyJSON,
      is_active: true,
      status: "draft",
    };
    const postPayload: PostPayload = {
      name: surveyJSON.title,
      // survey_data: surveyJSON,
      is_template: isTemplate,
      status: "draft",
    };
    try {
      if (isTemplate) {
        if (isSurveyValid(surveyJSON)) {
          const { data } = await axiosInstance.post<SurveysPostResponse>(
            "surveys",
            postPayload
          );
          const presignedUrlPUT = await getPresignedUrl(
            "put_object",
            `surveys/${data.survey_id}.json`
          );

          await saveSurveyData(surveyJSON, data.survey_id, presignedUrlPUT);
          setTimeout(() => {
            enqueueSnackbar(t("surveys.saved_as_template"), {
              variant: "success",
            });
            history.push(`/admin/survey_templates`);
            setIsSubmitting(false);
          }, 200);
        } else {
          enqueueSnackbar(
            t("errors.verify_all_questions_have_a_valid_category_and_group"),
            {
              variant: "error",
            }
          );
          setIsSubmitting(false);
        }
      } else if (isSurveyValid(surveyJSON)) {
        await axiosInstance.put("surveys", putPayload);

        const presignedUrlPUT = await getPresignedUrl(
          "put_object",
          `surveys/${surveyId}.json`
        );
        await saveSurveyData(surveyJSON, surveyId, presignedUrlPUT);

        setTimeout(() => {
          enqueueSnackbar(t("surveys.survey_updated"), {
            variant: "success",
          });
          history.push(`/accounts/${id}/surveys`);
          setIsSubmitting(false);
        }, 200);
      } else {
        enqueueSnackbar(
          t("errors.verify_all_questions_have_a_valid_category_and_group"),
          {
            variant: "error",
          }
        );
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      errorHandler(e, enqueueSnackbar, t);
    }
  };
  useEffect(() => {
    getSurveyData();
  }, []);

  return (
    <CreateSurveyComponent
      onSubmit={editSurvey}
      surveyData={surveyData}
      buttonLabel="general.update"
      buttonDisabled={isSubmitting}
      readOnly={readOnly}
      saveSurvey
    />
  );
}
