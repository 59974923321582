import { Box } from "@mui/material";
import { Container, Empty } from "@neostella/neostella-ui";
import { useTranslation } from "react-i18next";

export default function SurveyCompleted() {
  const [t] = useTranslation();
  return (
    <Container
      title={t("surveys.survey_completed")}
      onBack={false}
      sx={{ minWidth: "300px" }}
    >
      <Box
        sx={{
          m: 6,
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Empty icon="book" text={t("surveys.survey_completed_message")} />
      </Box>
    </Container>
  );
}
