import { PermissionWrapper } from "@neostella/neostella-ui";
import { Route, Switch } from "react-router-dom";
import Categories from "./Categories";
import CreateCategory from "./CreateCategory";
import EditCategory from "./EditCategory";

export default function CategoryRouter() {
  return (
    <Switch>
      <Route path="/admin/categories/create">
        <PermissionWrapper
          permissionKeys={["admin.categories.general.can_create"]}
          type="hide"
          redirect="/admin"
        >
          <CreateCategory />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/categories/:id">
        <PermissionWrapper
          permissionKeys={["admin.categories.general.can_create"]}
          type="hide"
          redirect="/admin"
        >
          <EditCategory />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/categories">
        <PermissionWrapper
          permissionKeys={["admin.categories.general.can_read"]}
          type="hide"
          redirect="/admin"
        >
          <Categories />
        </PermissionWrapper>
      </Route>
    </Switch>
  );
}
