import { actionType } from "./index";

type stateType = object | null | false;
const initialState: stateType = null;

export default (
  state: stateType = initialState,
  { type, payload }: actionType
) => {
  switch (type) {
    case "SIGN_IN":
      return payload;
    case "SIGN_OUT":
      return false;
    case "UPDATE_USER":
      if (typeof state === "object")
        return {
          ...state,
          ...payload,
        };
      return false;
    default:
      return state;
  }
};
