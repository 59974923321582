import { actionType } from "./index";

interface stateType {
  drawerCollapsed: boolean;
  currentTenant: any;
  lang: string;
  themeMode: string;
  themeStyle: string;
  currentThemeMode: string;
  preventBack: true | false;
}
const initialState: stateType = {
  drawerCollapsed: false,
  currentTenant: null,
  lang: localStorage.getItem("lang") || "en",
  themeMode: localStorage.getItem("themeMode") || "inherited",
  themeStyle: localStorage.getItem("themeStyle") || "",
  currentThemeMode: "light",
  preventBack: false,
};

export default (
  state: stateType = initialState,
  { type, payload }: actionType
) => {
  switch (type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        drawerCollapsed: !state.drawerCollapsed,
      };
    case "SET_TENANT":
      return {
        ...state,
        currentTenant: payload,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        lang: payload,
      };
    case "SET_THEME":
      return {
        ...state,
        themeMode: payload.themeMode,
        themeStyle: payload.themeStyle,
      };
    case "SET_CURRENT_THEME":
      return {
        ...state,
        currentThemeMode: payload.currentThemeMode,
      };
    case "PREVENT_BACK":
      return {
        ...state,
        preventBack: payload,
      };
    default:
      return state;
  }
};
