/* eslint-disable react/no-unescaped-entities */
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#522d6d",
          color: "white",
          width: "100%",
          height: 80,
          mb: 10,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            position: "relative",
            top: 15,
          }}
        >
          Terms and Conditions
        </Typography>
      </Box>
      <Paper
        elevation={1}
        sx={{
          p: 10,
          backgroundColor: "white",
          display: "flex",
          width: "50%",
          margin: "10px auto",
        }}
      >
        <Box>
          <Typography sx={{ textAlign: "center", fontWeight: "bold", m: 2 }}>
            The FamilyNavigator
          </Typography>
          <Typography sx={{ textAlign: "center", fontWeight: "bold", m: 2 }}>
            BY LOGGING INTO THIS SITE, YOU (“AUTHORIZED RESPONDENT”, AS DEFINED
            BELOW) ARE INDICATING YOU ACCEPT AND AGREE TO THE FOLLOWING TERMS
            AND CONDITIONS
          </Typography>
          <Typography sx={{ textAlign: "center", m: 2 }}>
            BFBA (defined below) reserves the right to modify these Terms and
            Conditions. Accordingly, please review this page often. BFBA will
            give notice of changes to the Terms and Conditions by posting the
            revised Terms and Conditions on this site, together with the date
            the Terms and Conditions were last updated. Modifications will be
            effective immediately upon posting. Your continued use of this site
            indicates your agreement to be bound by the modified Terms and
            Conditions. If you do not agree to be bound by the Terms and
            Conditions, promptly exit this site.
          </Typography>
          <Typography variant="h4" sx={{ textAlign: "center", m: 2 }}>
            TERMS AND CONDITIONS
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            1. For Use By Authorized Respondents Only: This site, the
            FamilyNavigator, the FamilyNavigator Reports (defined below) and all
            related materials (collectively, the “FamilyNavigator Materials”)
            are for the use and benefit of those users (each, an “Authorized
            Respondent”) referred to this site by and working with a family
            business consultant (the “Primary Consultant”) only. By logging on
            and using this site and related FamilyNavigator Materials, you
            represent that you are an Authorized Respondent who has been
            provided with the necessary passwords and you signify your
            understanding that all of the documents within this site are
            copyrighted by Bench Family Business Analytics LLC (Hereinafter
            referred to as “BFBA”) and that all rights to the copyrighted
            materials are reserved by BFBA.{" "}
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            2. Confidentiality: The responses made on the questionnaire will be
            used to generate a “FamilyNavigator Report”. The FamilyNavigator
            Report does not disclose an individual's responses to the individual
            items in the questionnaire. Instead, the FamilyNavigator Report
            compiles the responses to all the questions into a set of 12
            distinct competencies by averaging all of the respondents' numerical
            answers. For each of these 12 competencies, the FamilyNavigator
            Report provides summaries which show averages by rater category
            (e.g., senior family, successor, board member, key employee,
            partner, advisor) to which the respondents have been assigned by the
            Primary Consultant. Please be advised if there is only one
            respondent in a particular rater category, it will be possible to
            identify his or her average score within any one of the 12
            competencies. Therefore, we strongly recommend the Primary
            Consultant carefully consider how he or she assigns individual
            respondents to the rater categories and whether it is desirable to
            assign at least two or more individuals to each rater category to
            avoid a possible compromising of confidentiality. The completed
            FamilyNavigator Report is available to the Primary Consultant (i.e.,
            that is the person who contracted with BFBA for use of the
            FamilyNavigator materials) by download only. That download is done
            by the Primary Consultant through a login process which requires the
            password privately created by the Primary Consultant. Therefore, the
            Primary Consultant has complete control over when and to whom the
            FamilyNavigator Report is distributed. The Primary Consultant
            working with his or her client will determine how and to whom the
            FamilyNavigator Report will be distributed. The individual responses
            to the questionnaire may be used for general research purposes.
            However, the individual responses of the respondents and the client
            family will be combined anonymously with the responses of many other
            families who complete the FamilyNavigator process and there will be
            no reference to the respondents or to the client family in these
            research projects.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            3. Usage Restrictions: Authorized Respondent agrees not to attempt
            or encourage attempts to use the BFBA site administration, scoring,
            and reporting system in any unauthorized, unethical, or illegal
            manner. Additionally, Authorized Respondent agrees not to: (i) use
            the site in any manner that could disable, overburden, damage, or
            impair the site or interfere with any other party's use of the site;
            (ii) introduce any viruses, Trojan horses, worms, logic bombs, or
            other material that is malicious or technologically harmful; (iii)
            attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the site; or (iv) otherwise attempt to
            interfere with the proper working of the site.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            4. Indemnity, Hold Harmless, and Warranty by Authorized Respondent:
            Authorized Respondent agrees to hold BFBA harmless from any
            proceedings, claims, suits or legal process a respondent, Primary
            Consultant, or any third-party initiates or joins against BFBA which
            is related in any way to Authorized Respondent’s use of the
            FamilyNavigator Report, FamilyNavigator Materials, FamilyNavigator
            process, or the site. Authorized Respondent shall indemnify BFBA for
            any claims, liabilities, damages, demands, judgments, reasonable
            attorneys' fees, costs and expenses (including actual time charges,
            at the then prevailing rates, of BFBA’s officers, principals, agents
            and employees incurred in responding to any such claims or demands)
            arising from or alleged to have arisen out of Authorized
            Respondent’s use of the FamilyNavigator Report, FamilyNavigator
            Materials, FamilyNavigator process, or the site.{" "}
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            5. Disclaimer: Action Requires Professional Advice. Authorized
            Respondent understands that the interpretations and general
            recommendations provided in the FamilyNavigator Report are based
            only on the data recorded by the individual respondents. The
            analysis is not based on direct, first- hand, personal or
            professional consulting assessment of the client family, its family
            enterprise(s) or any of the respondents. Consequently, this analysis
            should not be construed as providing professional advice. The
            interpretations and general recommendations are only suggestions
            about issues which should be explored with skilled professionals.
            Implementation and actions which may occur as a result of the
            FamilyNavigator Report should be taken only with direct professional
            and/or consulting advice from licensed professionals. Accordingly,
            persons should not act upon information in this report without
            seeking direct professional and consulting advice. Finally, the
            interpretations and general recommendations are based on information
            provided during a limited time period. The date of the
            interpretations and analysis should be carefully examined when
            utilizing the results in the FamilyNavigator Report, because
            attitudes and answers may change over time, outdated information may
            offer little positive value.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            6. Copyrights and Restrictions: All FamilyNavigator Materials
            offered by BFBA are copyrighted. Printing or reproducing copyrighted
            materials, including reproduction onto any electronic medium, is
            prohibited by law. BFBA grants limited permission to print or
            reproduce additional copies of the FamilyNavigator Materials and
            FamilyNavigator Reports for the sole purpose of distribution to the
            family enterprise and family in business which is being assessed
            through this FamilyNavigator process, provided that all copyright,
            trademark, and other proprietary notices shall be kept intact.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            7. Disclaimer of Warranties and Limitation of Liability: BFBA and
            Primary Consultant make no warranty representations, expressed or
            implied, of the accuracy, performance, or fitness for any particular
            purpose of the information or materials provided to Authorized
            Respondent by BFBA. Authorized Respondent's use of the site, its
            content, and any services or items obtained through the site is at
            Authorized Respondent's own risk. The site, its content, and any
            services or items obtained through the site are provided on an "AS
            IS" and "AS AVAILABLE" basis. In no event will BFBA be liable to
            Authorized Respondent for any special, consequential, or exemplary
            damages. Further, BFBA will not be liable for failure to perform its
            or their obligations if such failure is due to causes beyond BFBA's
            reasonable control. Authorized Respondent acknowledges that the
            Internet experiences periods of interrupted service which may affect
            service. Replacement of materials, or refund of all or a portion of
            the purchase price, at BFBA's sole option, constitutes the sole and
            exclusive remedy of the Authorized Respondent, in lieu of any and
            all actual, consequential, special, incidental or other remedies and
            damages in connection with the FamilyNavigator Materials, and any
            use or misuse thereof, and any FamilyNavigator Report or other
            services in connection therewith. Under no circumstances is BFBA
            liable for the Authorized Respondent’s expenses in using the
            FamilyNavigator Materials, for delays, or for costs of substitute
            materials, or for possible lost income, grants, profits, or any
            other special or consequential damages. These provisions apply to
            all orders accepted by BFBA and may not be modified by any means
            unless expressly agreed to in writing by BFBA.
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              m: 2,
            }}
          >
            8. Privacy. To access the site or some of the resources it offers,
            you may be asked to provide certain registration details or other
            information. It is a condition of your use of the site that all the
            information you provide on the site is correct, current, and
            complete. You agree that all information you provide to register
            with the site or otherwise, including, but not limited to, through
            the use of any interactive features on the site, is governed by our
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none", color: "blue" }}
            >
              {" "}
              Privacy Policy
            </Link>
            , and you consent to all actions we take with respect to your
            information consistent with our Privacy Policy.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            9. Termination. BFBA reserves the right, in its sole discretion, to
            terminate your access to all or part of this site, with or without
            cause, and with or without notice.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 2, mr: 2, mt: 2 }}>
            10. General Provisions:
          </Typography>
          <Box sx={{ p: 5 }}>
            <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 3 }}>
              (a) If any Authorized Respondent sends or transmits any
              communications or materials to BFBA by mail, e-mail, telephone, or
              otherwise, suggesting or recommending changes to the site or the
              FamilyNavigator Materials, including without limitation, new
              features or functionality relating thereto, or any comments,
              questions, suggestions, or the like ("Feedback"), BFBA is free to
              use such Feedback irrespective of any other obligation or
              limitation between such Authorized Respondent and BFBA governing
              such Feedback. Authorized Respondent hereby assigns to BFBA all
              right, title, and interest in, and BFBA is free to use, without
              any attribution or compensation to any party, any ideas, know-how,
              concepts, techniques, or other intellectual property rights
              contained in the Feedback, for any purpose whatsoever, although
              BFBA is not required to use any Feedback.
            </Typography>
            <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 3 }}>
              (b) These Terms and Conditions shall be construed according to the
              laws of the State of Delaware of the United States of America.
              Each party also agrees and hereby submits to the exclusive
              personal jurisdiction and venue of the state and federal courts
              sitting in Delaware and waives any jurisdictional, venue or
              inconvenient form of objections to such courts.
            </Typography>
            <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 3 }}>
              (c) Authorized Respondent represents that the person accepting
              these Terms and Conditions on Authorized Respondent behalf has the
              full authority to execute such contracts and agreements for
              Authorized Respondent.
            </Typography>
            <Typography sx={{ textAlign: "justify", ml: 3, mr: 3 }}>
              (d) In the event that any of the Terms and Conditions are held by
              a court or other tribunal of competent jurisdiction to be
              unenforceable, such provisions shall be limited or eliminated to
              the minimum extent necessary so that these Terms and Conditions
              shall otherwise remain in full force and effect.
            </Typography>
          </Box>

          <Typography sx={{ textAlign: "center", fontWeight: "bold", m: 2 }}>
            The FamilyNavigator Report is Not a Substitute for Professional
            Advice or Consulting Services
          </Typography>
          <Typography sx={{ textAlign: "center", fontWeight: "bold", m: 2 }}>
            Action Taken in Reliance on the FamilyNavigator Report
            Recommendations Requires Professional Advice or Consulting Services
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            In completing the questionnaire each Authorized Respondent agrees
            that the interpretations and general recommendations provided in the
            FamilyNavigator Report are based only on the data recorded by the
            individual Authorized Respondent. The analysis is not based on
            direct, first- hand, personal or professional consulting assessment
            of the family business. Consequently, this analysis should not be
            construed as providing professional advice to the family. The
            general recommendations and interpretations are only suggestions
            about issues which should be explored. Implementation and actions
            which may occur as a result of the FamilyNavigator Report should be
            taken only with direct professional or consulting advice from
            licensed professionals and firms. Accordingly, persons should not
            act upon information in this report without seeking direct
            professional or consulting advice. Finally, the interpretations and
            general recommendations are based on information provided during a
            limited time period. The date of the interpretations and analysis
            should be carefully examined when utilizing the results of the
            FamilyNavigator Report. Because attitudes and answers may change
            over time, outdated information may offer little positive value.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            Updated April 1, 2023
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
