import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import {
  AutoComplete,
  axiosInstance,
  Container,
  errorHandler,
  isSameObject,
  TextField,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { setPreventBack } from "../../../../redux/actions/settings";

interface CategoryResp {
  UUID: string;
  category: Category;
  result: string;
}

interface Category {
  category_id: string;
  parent_category: string | null;
  parent_name?: string | null;
  name: string;
  deep?: number;
  is_active: boolean;
  created_by?: string;
  created_by_name?: string;
  updated_by?: string;
  updated_by_name?: string;
  created_at?: string;
  updated_at?: string;
}

interface CategoriesResp {
  UUID: string;
  records: Records[];
  result: string;
}

interface Records {
  category_id: string;
  parent_category: null | string;
  name: string;
  deep: number;
  is_active: boolean;
  created_by: string;
  created_by_name: string;
  updated_by: null | string;
  updated_by_name: string | null;
  created_at: string;
  updated_at: string | null;
}

interface ParentCategoryResp {
  UUID: string;
  records: ParentCategory[];
  result: string;
}

interface ParentCategory {
  category_id: string | null;
  parent_category?: null | string;
  name: string;
  deep?: number;
  is_active?: boolean;
  created_by?: string;
  created_by_name?: string;
  updated_by?: null | string;
  updated_by_name?: string | null;
  created_at?: Date;
  updated_at?: Date | null;
}

const validationSchema = yup.object({
  category_name: yup.string().required("errors.required_field"),
  parent_category: yup.string().nullable(),
});

export default function EditCategory() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { id }: any = useParams();
  const [categories, setCategories] = useState<Records[] | null>(null);
  const [loading, setLoading] = useState(true);

  const [initialValues, setInitialValues] = useState({
    category_id: id,
    category_name: "",
    parent_category: "",
    is_active: true,
  });
  const [parentCategories, setParentCategories] = useState<
    {
      value: any;
      label: string;
      parent_category: any;
    }[]
  >([{ value: "", label: "", parent_category: null }]);
  const createForm = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await axiosInstance.put("categories", {
          category_id: id,
          category_name: values.category_name,
          parent_category: values.parent_category || null,
          is_active: values.is_active,
        });
        dispatch(setPreventBack(false));
        setTimeout(() => {
          enqueueSnackbar(t("admin.categories.category_updated"), {
            variant: "success",
          });
          history.push("/admin/categories");
        }, 200);
      } catch (e) {
        setSubmitting(false);
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });

  const getCategoriesData = async () => {
    try {
      const { data: categoryResp } = await axiosInstance.get<CategoriesResp>(
        "categories?is_active=enabled"
      );
      setCategories(categoryResp.records);
      const { data: res } = await axiosInstance.get<CategoryResp>(
        `categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        }
      );

      setInitialValues({
        category_id: id,
        category_name: res.category.name,
        parent_category: res.category.parent_category || "",
        is_active: res.category.is_active,
      });
      setLoading(false);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    textAlign: "center",
    p: 4,
  };

  const ConfirmationModal = () => (
    <Modal open={open}>
      <Paper sx={style} component="form" onSubmit={createForm.handleSubmit}>
        <Typography variant="h5">
          {t("admin.categories.deactivate_parent_category")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{ m: 2 }}
            fullWidth
          >
            {t("general.no")}
          </Button>
          <Button variant="outlined" type="submit" sx={{ m: 2 }} fullWidth>
            {t("general.yes")}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );

  const getParentCategories = async () => {
    const { data: res } = await axiosInstance.get<ParentCategoryResp>(
      "categories?limit_deep=true"
    );
    setParentCategories(
      res.records.map((pc) => ({
        value: pc.category_id ? pc.category_id : "",
        label: pc.name.split("/").slice(-1).join(),
        parent_category: pc.parent_category,
      }))
    );
  };

  const detectedChanges = !isSameObject(
    createForm.initialValues,
    createForm.values
  );

  const onDeactivateParent = () => {
    if (
      (categories || []).filter(
        (category: any) =>
          category.parent_category === createForm.values.category_id
      ).length > 0
    ) {
      if (
        initialValues.is_active !== createForm.values.is_active &&
        !createForm.values.is_active
      ) {
        setOpen(true);
      }
    } else {
      createForm.handleSubmit();
    }
  };

  useEffect(() => {
    getCategoriesData();
    getParentCategories();
  }, [createForm.values]);

  if (loading) {
    return (
      <Box
        sx={{
          width: 1,
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {ConfirmationModal()}
      <Container title="Edit Category">
        <Box sx={{ p: 4 }} component="form" onSubmit={createForm.handleSubmit}>
          <Box>
            <FormControlLabel
              sx={{ mb: 4 }}
              control={
                <Switch
                  checked={createForm.values.is_active}
                  onChange={(e) =>
                    createForm.setFieldValue("is_active", e.target.checked)
                  }
                />
              }
              label={
                createForm.values.is_active
                  ? t("general.active")
                  : t("general.inactive")
              }
            />
          </Box>
          <TextField
            form={createForm}
            margin="none"
            fullWidth
            label={t("admin.categories.category_name")}
            name="category_name"
            type="text"
            noAutocomplete
            sx={{ mb: 2 }}
          />
          <AutoComplete
            options={parentCategories}
            getOptionLabel={(option: string) => {
              if (option === null) return "";

              const label = parentCategories.find(
                (c: { value: string; label: string }) => c.value === option
              )?.label;

              return label || "";
            }}
            onChange={(e, v) => {
              if (v === null) {
                return createForm.setFieldValue("parent_category", "");
              }
              return createForm.setFieldValue("parent_category", v.value);
            }}
            extraProps={{
              filterOptions: (
                options: { label: string; value: string }[],
                state: { inputValue: string }
              ) => {
                const searchResult: any = [];
                options.forEach((option) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  ) {
                    searchResult.push(option);
                  }
                });
                return searchResult;
              },
            }}
            name="parent_category"
            form={createForm}
            label={t("admin.categories.parent_category")}
            sx={{ mb: 3 }}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ flex: 1, m: 1 }}
              variant="contained"
              onClick={() => history.push("/admin/categories")}
            >
              {t("general.cancel")}
            </Button>
            <Button
              onClick={() =>
                !createForm.values.is_active &&
                createForm.values.is_active !== initialValues.is_active
                  ? onDeactivateParent()
                  : createForm.handleSubmit()
              }
              sx={{ flex: 1, m: 1 }}
              variant="contained"
              disabled={
                createForm.isSubmitting ||
                !createForm.isValid ||
                !detectedChanges
              }
            >
              {t("general.update")}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
