import { Box } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import SurveyCompleted from "./Surveys/SurveyCompleted";
import SurveyRunner from "./Surveys/SurveyRunner";

export default function AdditionalPublicRoutesRouter() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        height: "100%",
      }}
    >
      {/* p stands for public */}
      <Switch>
        <Route exact path="/p/survey/:survey_id">
          <SurveyRunner />
        </Route>
        <Route exact path="/p/survey">
          <Redirect to="/" />
        </Route>
        <Route exact path="/p/survey-completed">
          <SurveyCompleted />
        </Route>
        <Route exact path="/p">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Box>
  );
}
