import { axiosInstance, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { SurveyCreator } from "survey-creator-react";
import usePresignedUrls from "../../../../hooks/usePresignedUrls";
import { SurveysPostResponse } from "./AccountSurveysCreateFromScratch";
import CreateSurveyComponent, { PostPayload } from "./CreateSurveyComponent";

export interface SurveyByIdResp {
  UUID: string;
  survey: Survey[];
  result: string;
}

interface Survey {
  survey_id: string;
  is_template: boolean;
  name: string;
  is_active: boolean;
  status_type_id: string;
  status_type_name: string;
  created_by: string;
  created_by_name: string;
  updated_by: string;
  updated_by_name: string;
  created_at: Date;
  updated_at: Date;
}

export interface SurveyData {
  title: string;
  logoPosition: string;
  pages: Page[];
  showQuestionNumbers: string;
}

interface Page {
  name: string;
  elements: Element[];
}

export interface Element {
  type: string;
  name: string;
  title: string;
  groups?: string[];
  categories?: string[];
  rateMax?: number;
}

export default function AccountSurveysCreateFromTemplate() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { surveyId, id }: any = useParams();
  const [t] = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [surveyTemplateData, setSurveyTemplateData] =
    useState<SurveyData | null>(null);
  const { getPresignedUrl, fetchSurveyData, saveSurveyData, isSurveyValid } =
    usePresignedUrls();

  const getSurveyData = async () => {
    const presignedUrlGET = await getPresignedUrl(
      "get_object",
      `surveys/${surveyId}.json`
    );

    const surveyDataJson = await fetchSurveyData<SurveyData>(
      presignedUrlGET.presigned_url.url
    );
    setSurveyTemplateData(surveyDataJson);
  };

  const createSurvey = async (creator: SurveyCreator) => {
    setIsSubmitting(true);
    const surveyJson = creator.JSON;
    setSurveyTemplateData(surveyJson);
    const postPayload: PostPayload = {
      account_id: id,
      name: surveyJson.title,
      // survey_data: surveyJson,
      is_template: false,
      status: "draft",
    };
    try {
      if (isSurveyValid(surveyJson)) {
        const { data } = await axiosInstance.post<SurveysPostResponse>(
          "surveys",
          postPayload
        );
        const presignedUrlPUT = await getPresignedUrl(
          "put_object",
          `surveys/${data.survey_id}.json`
        );

        await saveSurveyData(surveyJson, data.survey_id, presignedUrlPUT);
        setTimeout(() => {
          enqueueSnackbar(t("surveys.survey_created"), {
            variant: "success",
          });

          history.push(`/accounts/${id}/surveys`);
        }, 200);
        setIsSubmitting(false);
      } else {
        enqueueSnackbar(
          t("errors.verify_all_questions_have_a_valid_category_and_group"),
          {
            variant: "error",
          }
        );
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    getSurveyData();
  }, []);

  return (
    <CreateSurveyComponent
      onSubmit={createSurvey}
      surveyData={surveyTemplateData}
      buttonDisabled={isSubmitting}
    />
  );
}
