import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./common/en";
import es from "./common/es";

const resources = {
  en: { ...en() },
  es: { ...es() },
};

const lang = localStorage.getItem("lang") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
