import { LicenseInfo } from "@mui/x-license-pro";
import { Core, VersionManager } from "@neostella/neostella-ui";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import packageInfo from "../package.json";
import {
  dashboardRoutes,
  drawerItems,
  languages,
  rootRoute,
} from "./appSettings";
import AdditionalPublicRoutesRouter from "./pages/Dashboard/AdditionalPublicRoutes/AdditionalPublicRoutesRouter";
import PrivacyPolicy from "./pages/Dashboard/AdditionalPublicRoutes/Privacy Policy/PrivacyPolicy";
import TermsAndConditions from "./pages/Dashboard/AdditionalPublicRoutes/Terms/TermsAndConditions";
import { signIn } from "./redux/actions/user";
import store from "./redux/store";
import getDesignTokens from "./styles/getDesignTokens";
import "./styles/index.css";
import "./translations/i18n";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_X_LK}`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <VersionManager version={packageInfo.version}>
        <Core
          RootState={store}
          getDesignTokens={getDesignTokens}
          dashboardRoutes={dashboardRoutes}
          rootRoute={rootRoute}
          languages={languages.map((l) => l.code)}
          signIn={signIn}
          drawerItems={drawerItems}
          additionalPublicRoutes={[
            {
              element: <AdditionalPublicRoutesRouter />,
              path: "/p",
            },
            {
              element: <PrivacyPolicy />,
              path: "/privacy-policy",
            },
            {
              element: <TermsAndConditions />,
              path: "/terms",
            },
          ]}
        />
      </VersionManager>
      <p
        style={{
          position: "fixed",
          bottom: 15,
          right: 15,
          opacity: 0.5,
          fontSize: 15,
          userSelect: "none",
        }}
      >
        {packageInfo.version}
      </p>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
