import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  GridColDef,
  GridRowParams,
  GridSelectionModel,
} from "@mui/x-data-grid-premium";
import {
  AutoComplete,
  axiosInstance,
  DataGrid,
  Empty,
  errorHandler,
  isSameObject,
  useFetch,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Container from "../../../../components/Container";
import { UsersGroupsResp } from "./CreateSurveyComponent";

interface AutocompleteOption {
  label: string;
  value: string;
}
interface SurveyUsersResp {
  UUID: string;
  count: number;
  records: SurveyUser[];
  result: string;
  survey_name: string;
}

interface SurveyUser {
  cognito_user_id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  user_group_account_id: string | null;
  user_group_id: string | null;
  group_name: string | null;
  email: string;
  email_sent: string | null;
  status_name: string | null;
}

interface SurveyUsersPutResp {
  UUID: string;
  survey_id: string;
  user_group_account_id: string;
  result: string;
}

interface AccountUsers {
  [cognito_uiser_id: string]: AccountUserProperties;
}

interface AccountUserProperties {
  cognito_user_id: string;
  user_group_id: string;
  user_group_account_id: string | null;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  group_name: string;
  status_name: string;
}

const initialValues: AccountUsers = {
  default: {
    cognito_user_id: "",
    user_group_id: "",
    user_group_account_id: "",
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    group_name: "",
    status_name: "",
  },
};

export default function AccountSurveysSendSurvey() {
  const [t] = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [accountUsers, setAccountUsers] = useState<AccountUsers>(initialValues);
  const [surveyName, setSurveyName] = useState<null | string>(null);
  const [selectionModel, setSelectionModel] =
    useState<GridSelectionModel | null>(null);

  const { id, surveyId } = useParams<{
    id: string;
    surveyId: string;
  }>();

  const groups = useFetch<UsersGroupsResp>("usergroups?is_active=enabled");

  const getUsers = async () => {
    const { data } = await axiosInstance.get<SurveyUsersResp>(
      `/surveyusers?account_id=${id}&survey_id=${surveyId}`
    );

    const newUsers: AccountUsers = {};
    data.records.forEach((u) => {
      newUsers[u.cognito_user_id] = {
        cognito_user_id: u.cognito_user_id,
        user_group_id: u.user_group_id || "",
        user_group_account_id: u.user_group_account_id,
        full_name: u.full_name,
        first_name: u.first_name,
        last_name: u.last_name,
        email: u.email,
        group_name: u.group_name || "",
        status_name: u.status_name || "",
      };
    });
    setSurveyName(data.survey_name);
    setAccountUsers(newUsers);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: accountUsers,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        survey_id: surveyId,
        users: (Array.isArray(selectionModel) ? selectionModel : []).map(
          (s) => ({
            send_email: true,
            cognito_user_id: s,
            user_group_id: values[s].user_group_id,
            user_group_account_id: values[s].user_group_account_id,
          })
        ),
      };
      setSubmitting(true);
      try {
        await axiosInstance.put("surveyusers", payload);
        setTimeout(() => {
          enqueueSnackbar(t("surveys.survey_sent_successfully"), {
            variant: "success",
          });
          history.push(`/accounts/${id}/surveys`);
        }, 200);
      } catch (e) {
        setSubmitting(false);
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });

  useEffect(() => {
    getUsers();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "full_name",
      headerName: t("general.full_name"),
      minWidth: 300,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: t("general.first_name"),
      width: 250,
    },
    {
      field: "last_name",
      headerName: t("general.last_name"),
      width: 250,
    },
    {
      field: "email",
      headerName: t("general.email"),
      width: 300,
    },

    {
      field: "group_name",
      headerName: t("general.group_name"),
      width: 300,
      renderCell: (params) => (
        <AutoComplete
          size="small"
          sx={{ mx: 2 }}
          fullWidth
          disableClearable
          form={form}
          label=""
          name={String(params.id)}
          getOptionLabel={(v: AccountUserProperties) => v.group_name || ""}
          options={
            groups.response
              ? groups.response.records.map((g) => ({
                  label: g.name,
                  value: g.user_group_id,
                }))
              : [{ label: "", value: "" }]
          }
          onChange={async (e, v: AutocompleteOption) => {
            // Update User Group
            form.setSubmitting(true);
            const payload = {
              survey_id: surveyId,
              users: [
                {
                  send_email: false,
                  cognito_user_id: params.id,
                  user_group_id: v.value,
                  user_group_account_id: params.row.user_group_account_id,
                },
              ],
            };
            const temporaryValue = {
              ...form.values[String(params.id)],
              group_name: v.label,
            };
            // Had to make 2 setFieldValue so the autocomplete shows the group while loading
            form.setFieldValue(String(params.id), temporaryValue);
            try {
              const { data } = await axiosInstance.put<SurveyUsersPutResp>(
                "surveyusers",
                payload
              );
              const newValue = {
                ...form.values[String(params.id)],
                user_group_id: v.value,
                group_name: v.label,
                user_group_account_id: data.user_group_account_id,
              };
              form.setFieldValue(String(params.id), newValue);
              form.setSubmitting(false);
              enqueueSnackbar(t("surveys.group_saved_successfully"), {
                variant: "success",
              });
            } catch (err) {
              form.setSubmitting(false);
              errorHandler(err, enqueueSnackbar, t);
            }
          }}
        />
      ),
    },
    {
      field: "status",
      headerName: t("general.status"),
      width: 200,
    },
  ];
  return (
    <Container
      title={
        surveyName
          ? `${t("surveys.send_survey")}: ${surveyName}`
          : t("surveys.send_survey")
      }
      onBack={() => history.goBack()}
      actions={[
        {
          title: t("accounts.create_account_user"),
          titleIcon: <PersonAddIcon />,
          onClick: () =>
            history.push(
              `/accounts/${id}/create_user?redirect=${encodeURI(
                `/accounts/${id}/survey/send/${surveyId}`
              )}`
            ),
          permissionKeys: ["admin.users.general.can_create"],
          variant: "outlined",
        },
        {
          title: t("general.send"),
          titleIcon: <ForwardToInboxIcon />,
          onClick: () => form.handleSubmit(),
          permissionKeys: ["admin.users.general.can_create"],
          disabled:
            selectionModel === null ||
            selectionModel.length === 0 ||
            form.isSubmitting,
        },
      ]}
    >
      <>
        <DataGrid
          sx={{ height: 480, m: 4 }}
          extraProps={{
            autoHeight: !isSameObject(accountUsers, initialValues),
            checkboxSelection: true,
            isRowSelectable: (params: GridRowParams) =>
              params.row.user_group_id !== "",
            onSelectionModelChange: (newSelectionModel: GridSelectionModel) => {
              setSelectionModel(newSelectionModel);
            },
          }}
          columns={columns}
          rows={
            isSameObject(form.values, initialValues)
              ? []
              : Object.entries(form.values).map(([key, r]) => ({
                  id: key,
                  full_name: r.full_name,
                  first_name: r.first_name,
                  last_name: r.last_name,
                  email: r.email,
                  user_group_account_id: r.user_group_account_id,
                  user_group_id: r.user_group_id,
                  group_name: r.group_name,
                  status: r.status_name ? r.status_name : "N/A",
                }))
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                first_name: false,
                last_name: false,
              },
            },
          }}
          loading={isSameObject(form.values, initialValues)}
          quickFilter
          disableSelectionOnClick
          noRowsOverlay={() => (
            <Empty
              sx={{ pb: 16 }}
              icon="cancel"
              text={t("general.no_users_found")}
            />
          )}
        />
      </>
    </Container>
  );
}
