const getValues = () => ({
  translation: {
    errors: {
      changes_you_made_may_not_be_saved: "Changes you made may not be saved",
      code_limit_exceeded:
        "You exceeded the limit of codes, try again in a few minutes",
      enter_a_new_value: "Enter a new value",
      enter_code: "Enter the 6 digit code",
      enter_email: "Enter your email",
      enter_password: "Enter your password",
      invalid_email: "Invalid email",
      invalid_email_or_password: "Invalid email or password",
      not_found: "Not found",
      number_must_be_positive_integer: "Number must be a positive integer",
      object_limit_reached: "Limit reached",
      password_no_match: "Passwords don't match",
      password_requirements:
        "Must contain at least 8 characters, one uppercase, one lowercase, one number, one symbol",
      required_field: "Required field",
      role_no_access: "Role does not have access to this feature",
      select_a_value: "Select a value",
      select_at_least_one_permission: "Select at least one permission",
      select_at_least_one_role: "Select at least one role",
      write_at_least_one_value: "Write at least one value",
      something_went_wrong: "Something went wrong",
      tenant_no_access: "Tenant does not have access to this feature",
      tenant_not_found: "Tenant not found",
      user_already_exists: "User already exists",
      wrong_code: "The code you entered is invalid",
      invalid_phone: "Invalid phone",
      invalid_ssn: "Invalid SSN",
      invalid_date: "Invalid Date",
      invalid_date_range: "Invalid Date Range",
    },
    general: {
      my_account: "My Account",
      system_settings: "System Settings",
      permissions: "Permissions",
      actions: "Actions",
      active: "Active",
      address: "Address",
      admin: "Admin",
      cancel: "Cancel",
      code: "Verification code",
      component: "Component",
      continue: "Continue",
      create: "Create",
      create_user: "Create User",
      customer_name: "Customer Name",
      custom: "Custom",
      dashboard: "Dashboard",
      default: "Default",
      delete: "Delete",
      disabled: "Disabled",
      edit: "Edit",
      edit_user: "Edit User",
      email: "Email",
      en: "English",
      enabled: "Enabled",
      es: "Spanish",
      first_name: "First Name",
      function: "Function",
      go: "Go",
      go_back: "Go back",
      inactive: "Inactive",
      language: "Language",
      last_name: "Last Name",
      login: "Login",
      logout: "Log out",
      module: "Module",
      name: "Name",
      new_value: "New value",
      value: "Value",
      choose_a_value: "Choose a value",
      choose_a_filter: "Choose a filter",
      add_filter: "Add filter",
      password: "Password",
      resend: "Resend code",
      save: "Save",
      search: "Search",
      advanced_search: "Advanced Search",
      your_search_returned_no_results: "Your search returned no results",
      select: "Select",
      status: "Status",
      subcomponent: "Sub-Component",
      success: "Success!",
      thank_you: "Thank you!",
      update: "Update",
      user_created: "User created",
      user_settings: "User Settings",
      user_updated: "User updated",
      version: "Version",
      prefix: "Prefix",
      suffix: "Suffix",
      middle_name: "Middle name",
      pronoun: "Pronoun",
      mr: "Mr.",
      ms: "Ms.",
      mrs: "Mrs.",
      sr: "Sr.",
      jr: "Jr.",
      she: "She/Her",
      he: "He/Him",
      they: "They/Them",
      phone: "Phone",
      ssn: "Social Security Number (SSN)",
      tax_id: "Tax ID",
      line_one: "Line 1",
      line_two: "Line 2",
      zip_code: "ZIP",
      state: "State",
      city: "City",
      county: "County",
      parcel: "Parcel ID",
      theme_preferences: "Theme Preferences",
      select_custom_theme: "Select Custom Theme",
      number: "Number",
      state_abbreviation: "State Abbreviation",
      product_code: "Product Code",
      hyphen: "Hyphen",
      custom_code: "Custom Code",
      work_phone: "Work Phone",
      ownership_vesting: "Ownership Vesting",
      signature_line: "Signature Line",
      theme_options: {
        inherited: "Inherited from browser",
        custom: "Custom",
      },
      themeStyle: {
        light: "Light",
        dark: "Dark",
      },
      date: "Date",
      pick_a_date: "Pick a Date",
      loading_ellipsis: "Loading...",
      from: "From",
      to: "To",
    },
    authentication: {
      an_email_has_been_sent:
        "A verification code has been sent to {{email}}, please complete the fields below to reset your password",
      are_you_sure_logout: "Are you sure you want to Log Out?",
      back_to_login: "Back to login",
      code_sent: "Code sent",
      create_new_password: "Create new password",
      forgot_password: "Forgot password",
      forgot_your_password: "Forgot your password?",
      new_password: "New password",
      password_resetted:
        "Your password has been successfully changed. You will be redirected to the Login screen",
      please_enter_password_below: "Please enter your new password below",
      please_enter_the_email_you_use_to_login:
        "Please enter the email you use to login to this application",
      repeat_new_password: "Repeat new password",
      repeat_password: "Repeat password",
      reset_password: "Reset password",
    },
    admin: {
      system_languages: "System Languages",
      users: "Users",
      show_inactive_users: "Show Inactive Users",
      show_inactive_customers: "Show Inactive Customers",
      languages: {
        add_custom_translation: "Add custom translation",
        update_custom_translation: "Update custom translation",
        system_value: "System value",
        custom_value: "Custom value",
        sure_remove_translation:
          '"{{word}}" will be replaced with "{{default}}"',
        there_are_no_translations:
          "There are no custom translations for this language",
      },
      customers: {
        create_customer: {
          add_new_shipping_address: "Add new shipping address",
        },
      },
      roles: {
        roles: "Roles",
        show_inactive_roles: "Show Inactive Roles",
        create_role: "Create Role",
        role_created: "Role created",
        edit_role: "Edit role",
        role_not_found: "Role not found",
        role_updated: "Role updated",
        values: {
          admin: "Admin",
          user_settings: "User Settings",
          users: "Users",
          orders: "Orders",
          translations: "Translations",
          roles: "Roles",
          general: "General",
          can_create: "Create",
          can_read: "Read",
          can_update: "Update",
          can_delete: "Delete",
          customers: "Customers",
        },
        sure_remove_role:
          'Deleting Role: "{{role_name}}" could impact existing system users. Do you want to continue?',
      },
    },
    orders: {
      orders: "Orders",
      order: "Order",
      no_orders: "There are no orders",
      order_not_found: "Order not found",
      create_order: "Create Order",
      order_created: "Order created",
      quick_access: "Quick Access",
      order_number: "Order Number",
      address_before_closing: "Address Before Closing",
      address_after_closing: "Address After Closing",
      same_as_property_address: "Same as Property Address",
      same_as_before_closing: "Same as Before Closing",
      properties: {
        properties: "Properties",
        property: "Property",
        add_property: "Add new property",
        address: "Address",
        primary_address: "Primary Address",
        property_address: "Property Address",
      },
      parties: {
        parties: "Parties",
        party: "Party",
        party_name: "Party Name",
        buyer: "Buyer",
        seller: "Seller",
        borrower: "Borrower",
        individual: "Individual",
        corporation: "Corporation",
        partnership: "Partnership",
        trust: "Trust",
        party_type: "Party type",
        entity_type: "Entity type",
        add_party: "Add new party",
      },
      order_format: {
        order_number_format: "Order Number Format",
        integer_value: "Integer value",
        state_abbreviation: "State abbreviation",
        county_code: "County code",
        product: "Product",
        start_at: "Start at",
        preview: "Preview",
        number_is_required: "Number is required",
        order_number_format_updated: "Order Number Format updated",
      },
      party_name: "Party Name",
      party_phone_number: "Party Phone Number",
      party_email: "Party Email",
      party_address_line_1_before: "Address - Line 1",
      party_address_city_before: "Address - City",
      party_address_state_abbreviation_before: "Address - State Abbreviation",
      party_address_zip_before: "Address - ZIP",
      party_address_county_before: "Address - County",
      party_address_line_1_after: "Address - Line 1",
      party_address_city_after: "Address - City",
      party_address_state_abbreviation_after: "Address - State Abbreviation",
      party_address_zip_after: "Address - ZIP",
      party_address_county_after: "Address - County",
      property_address_line_1: "Address - Line 1",
      property_address_city: "Address - City",
      property_address_state_abbreviation: "Address - State Abbreviation",
      property_address_zip: "Address - ZIP",
      property_address_county: "Address - County",
      audit_trail_create_user: "Create User",
      audit_trail_created: "Created Date",
      categories: {
        orders: "Orders",
        parties: "Parties",
        party_address_before_closing: "Party Address (Before closing)",
        party_address_after_closing: "Party Address (After closing)",
        property_address: "Property Address",
        audit_trail: "Audit Trial",
      },
    },
    loans: {
      loans: "Loans",
      loan: "Loan",
      loan_number: "Loan Number",
    },
  },
});
export default getValues;
