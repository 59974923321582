import { PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";
import { enUS, esES } from "@mui/material/locale";

const langMap: any = {
  es: esES,
  en: enUS,
};

const spacing = 8;
const primary = "#522d6d";
const lang = localStorage.getItem("lang") || "en";

const getDesignTokens = (mode: PaletteMode) => ({
  spacing,
  palette: {
    grey: {
      50: "#f4f5f7",
    },
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: primary,
          },
          background: {
            paper: "#fff",
            default: "#e3f2fd",
          },
        }
      : {
          primary: {
            main: "#7c4dff",
          },
          background: {
            paper: "#111936",
            default: "#212a45",
          },
        }),
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: spacing,
              borderColor: grey[400],
            },
            "&.Mui-focused fieldset": {
              borderColor: primary,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${spacing * 1.5}px ${spacing}px`,
          borderRadius: spacing,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            borderRadius: 0,
          },
        },
      },
    },
  },
  ...langMap[lang],
});

export default getDesignTokens;
