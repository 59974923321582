import { Box, Button, Grid } from "@mui/material";
import {
  axiosInstance,
  Container,
  errorHandler,
  TextField,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function GroupsCreate() {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const createForm = useFormik({
    initialValues: { name: "" },
    onSubmit: async (values) => {
      try {
        await axiosInstance.post("usergroups", {
          name: values.name,
        });
        setTimeout(() => {
          enqueueSnackbar(t("admin.groups.group_created"), {
            variant: "success",
          });
          history.push("/admin/groups");
        }, 200);
      } catch (e) {
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });
  return (
    <Container title={t("admin.groups.create_group")}>
      <Box sx={{ p: 4 }} component="form" onSubmit={createForm.handleSubmit}>
        <Grid container>
          <Grid item xs={8} sx={{ margin: "auto" }}>
            <TextField
              form={createForm}
              name="name"
              label={t("general.name")}
              type="text"
              noAutocomplete
              margin="none"
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid xs={6} sx={{ display: "flex", margin: "auto" }}>
            <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }}>
              {t("general.create")}
            </Button>
            <Button
              onClick={() => history.push("/admin/groups")}
              fullWidth
              variant="contained"
              sx={{ m: 2 }}
            >
              {t("general.cancel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
