import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import {
  axiosInstance,
  Container,
  errorHandler,
  TextField,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";

interface GroupsResp {
  UUID: string;
  user_group: UserGroup;
  result: string;
}

interface UserGroup {
  user_group_id: string;
  name: string;
  is_active: boolean;
  created_by: string;
  created_by_name: string;
  updated_by: string;
  updated_by_name: string;
  created_at: string | null;
  updated_at: string | null;
}

const validationSchema = yup.object({
  name: yup.string().required("errors.required_field"),
});

export default function GroupsEdit() {
  const { id } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_group_id: id,
    name: "",
    is_active: false,
  });
  const [t] = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await axiosInstance.put("usergroups", {
          user_group_id: id,
          name: values.name,
          is_active: values.is_active,
        });
        setTimeout(() => {
          enqueueSnackbar(t("admin.groups.group_updated"), {
            variant: "success",
          });
          history.push("/admin/groups");
        }, 200);
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });

  const getGroups = async () => {
    try {
      setLoading(true);
      const { data: res } = await axiosInstance.get<GroupsResp>(
        `usergroups/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        }
      );
      setInitialValues({
        user_group_id: id,
        name: res.user_group.name,
        is_active: res.user_group.is_active,
      });
      setLoading(false);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      {loading ? (
        <Container title={t("admin.groups.edit_group")}>
          <Box sx={{ p: 4, display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <Container title={t("admin.groups.edit_group")}>
          <Box sx={{ p: 4 }} component="form" onSubmit={form.handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ mb: 4 }}
                  control={
                    <Switch
                      checked={form.values.is_active}
                      onChange={(e) =>
                        form.setFieldValue("is_active", e.target.checked)
                      }
                    />
                  }
                  label={
                    form.values.is_active
                      ? t("general.active")
                      : t("general.inactive")
                  }
                />
              </Grid>
              <Grid item xs={8} sx={{ margin: "auto" }}>
                <TextField
                  form={form}
                  name="name"
                  label={t("general.name")}
                  type="text"
                  noAutocomplete
                  margin="none"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", margin: "auto" }}>
                <Button
                  type="submit"
                  disabled={form.isSubmitting || !form.isValid}
                  fullWidth
                  variant="contained"
                  sx={{ m: 2 }}
                >
                  {t("general.update")}
                </Button>
                <Button
                  onClick={() => history.push("/admin/groups")}
                  fullWidth
                  variant="contained"
                  sx={{ m: 2 }}
                >
                  {t("general.cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}
