/* eslint-disable react/no-unescaped-entities */
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#522d6d",
          color: "white",
          width: "100%",
          height: 80,
          mb: 10,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            position: "relative",
            top: 15,
          }}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Paper
        elevation={1}
        sx={{
          p: 10,
          backgroundColor: "white",
          display: "flex",
          width: "50%",
          margin: "10px auto",
        }}
      >
        <Box>
          <Typography sx={{ textAlign: "center", m: 2, fontWeight: "bold" }}>
            Privacy Policy
          </Typography>
          <Typography sx={{ textAlign: "center", m: 2 }}>
            Last Updated: March 17, 2023
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            1. Introduction
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            Bench Family Business Analytics LLC ("BFBA", "us," "we," or "our,"
            as the context may require) operates and provides the BFBA
            FamilyNavigator questionnaire and FamilyNavigator process (the "
            FamilyNavigator Process," and the "Services"). This Privacy Policy
            (this "Policy") describes our privacy practices for the Services,
            including the types of information we may collect from you when you
            visit or use the Services and our practices for collecting, using,
            protecting, and disclosing that information. We respect the privacy
            of the users of the Services ("you" or "your," as the context may
            require) and we are committed to protecting your privacy and the
            security of your personal information through compliance with this
            Policy.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            Please read this Policy carefully to understand our practices
            regarding your personal information. By accessing or using the
            Services, you agree to this Policy and the
            <Link to="/terms" style={{ textDecoration: "none", color: "blue" }}>
              {" "}
              FamilyNavigator Process terms and conditions.{" "}
            </Link>
            If you do not agree with our policies and practices, please do not
            visit, access, use, or otherwise interact with the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            2. The Interactions this Policy Covers
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            This Policy describes and applies to personal information (as
            defined below) that we collect on or in connection with the Services
            and in texts, emails, and other electronic communications sent
            through or in connection with the Services. Except as otherwise
            provided in this Policy or as required by applicable law, this
            Policy does not apply to information that is collected by any
            application, website, or third party other than the Services,
            including through any application or content that may access, be
            linked to, or otherwise be accessible from the Services. If you
            visit an application, website, or third party that we mention or
            link to, be sure to review its privacy policy before providing it
            with any information, as that privacy policy will apply to your
            interactions with that application, website, or third party.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            Except as otherwise provided in this Policy, "personal information"
            is information that identifies, relates to, describes, references,
            is capable of being associated with, or could reasonably be linked,
            directly or indirectly, to you. "Personal information" does not
            include information which is generally not considered "personal
            information" under applicable law or is otherwise exempted under
            applicable law, such as information which is publicly available or
            subject to special data privacy laws and/or regulations.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            3. Information We Collect
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We collect several types of information from and about you as a
            result of your use of the Services and as a result of your
            communication with us. This information may include:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 2 }}>
            · Various categories of personal information, such as:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 6, mr: 6, mb: 2 }}>
            o Identifiers, including your name, address, email address, phone
            number, and other similar identifiers.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 6, mr: 6, mb: 2 }}>
            o Demographic information, such as your age, gender, household
            income, education, and other similar categories.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 6, mr: 6, mb: 2 }}>
            o Any other personal information that you voluntarily share on the
            Services, or which you otherwise provide directly to us.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 6, mr: 6, mb: 2 }}>
            o Inferences drawn from any of the above.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 2 }}>
            · Information that is about you but individually does not identify
            you, such as information which has been de-identified or aggregated
            in a manner where it can no longer be used to identify you.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 2 }}>
            · Information about your internet connection and the equipment you
            use to access the Services; IP address, approximate location, and
            other unique device identifiers; your activity on the Services; and
            other usage details collected through your use of the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 3, mr: 3, mb: 2 }}>
            · Any other information, including personal information, which you
            disclose or share.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            4. How We Collect Personal Information
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            • Directly from you when you provide it to us; and
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            • Automatically when you use or access the Services, through cookies
            and other tracking technologies.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We may collect personal information: We may also receive personal
            information or draw inferences about you from other sources,
            including service providers who collect or process information on
            our behalf. We generally require these service providers to use your
            personal information only as we direct and in a manner consistent
            with this Policy.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            A. Information We Collect Directly
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            The personal information we collect directly from you when you use
            or access the Services includes:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Information that you provide by filling in questionnaires or other
            forms on the Services. This also includes information provided at
            the time of requesting further information or reporting a problem
            with the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Your voluntary responses to surveys and other questionnaires that
            we might ask you to complete.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Records and copies of your communications when you contact us.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            B. Information We Collect Automatically
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            As you navigate through and interact with the Services, we may use
            automatic data collection technologies to collect certain
            information, including:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Usage details. When you access and use the Services, we may
            automatically collect certain details of your access to and use of
            the Services, including traffic data, approximate location data,
            logs, and other communication data and the resources that you access
            and use on or through the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Device information. We may collect information about your mobile
            device and internet connection, including the device's unique device
            identifier and IP address.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            The information we collect automatically may include personal
            information, or we may maintain it or may associate it with other
            personal or business information we collect in other ways or receive
            from third parties. This information helps us to improve our
            Services and to deliver a better and more personalized service,
            including by enabling us to:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            • Recognize you when you return to the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            The technologies we use for automatic information collection
            include:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            • Cookies. A cookie is a small file placed on the hard drive of your
            computer. We use both session-based and persistent cookies.
            Session-based cookies last only while your browser is open and are
            automatically deleted when you close your browser. Persistent
            cookies last until you or your browser delete them or until they
            expire. You may refuse to accept cookies by activating the
            appropriate setting on your browser. However, if you select this
            setting, you may be unable to access certain parts of our Services,
            and overall functionality of the Services may be impaired. For
            example, refusing to accept cookies may not allow you to stay signed
            into the Services. Unless you have adjusted your browser setting so
            that it will refuse cookies, our system will issue cookies when you
            direct your browser to our Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            5. How We Use Your Personal Information
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We may use the personal information that we collect about you or
            that you provide to us:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To provide you with the Services, all related content, and any
            other information, products, or services that you request from us
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To communicate with you and to process your requests.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To notify you of changes or additions to any products or services
            we offer.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To help maintain the safety, security, and integrity of the
            Services, as well as our products, services, databases, and other
            assets.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To carry out our obligations and enforce our rights arising from
            any contracts entered into between you and us, including for billing
            and collection.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To respond to law enforcement requests and, as required by
            applicable law, court order or governmental regulations.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To evaluate, negotiate, or conduct an actual or proposed merger,
            divestiture, restructuring, reorganization, dissolution, other sale
            or transfer of some or all of our assets, or the negotiation
            thereof, whether as a going concern or as part of bankruptcy,
            liquidation, or similar proceeding, in which personal information
            held by us about our users and customers is among the assets
            transferred.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · For testing, research, analysis, and product development,
            including to understand our audience, develop and improve the
            Services, as well as our customer service, products, and services.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To fulfill any other purpose for which you provide it.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · For any other purpose with your consent.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            6. Disclosure of Your Information
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We may disclose personal information that we collect or that you
            provide from any category identified above in Section 3 (Information
            We Collect):
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To our subsidiaries and affiliates.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To contractors, service providers, advisors, and other third
            parties we use to support our business and who are bound by
            contractual obligations to keep personal information confidential
            and use it only for the purposes for which we disclose it to them.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To an actual or perspective buyer or other successor in the event
            of an actual or proposed merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            information held by us about our users is among the assets
            transferred.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To fulfill the purpose for which you provide it.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To comply with any court order, law, or legal process, including
            to respond to any government or regulatory request.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · To enforce our rights arising from any contracts entered into
            between you and us, and for billing and collection.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · If we believe disclosure is necessary or appropriate to protect
            the rights, property, or safety of the Companies, our customers, or
            others.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · For any other purpose disclosed by us when you provide the
            information.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · With your consent.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We may use or disclose aggregated information about our users and
            information that does not identify any individual without
            restriction.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            7. Your Privacy Choices
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We strive to provide you with as many choices as possible regarding
            the personal information you provide to us. We offer the following
            mechanisms to provide you with the following control over your
            information:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            <b>· Tracking Technologies.</b> You can set your browser to refuse
            all or some browser cookies, or to alert you when cookies are being
            sent. If you disable or refuse cookies, some parts of the Services
            may then be inaccessible or not function properly.
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            <b>· Promotional Offers from Us.</b> If you do not wish to have your
            contact information used by us to promote our own products or
            services, you can opt-out by unsubscribing from any emails you
            receive from us. Additionally, you can also always opt-out by
            contacting us as provided in Section 12 (How to Contact Us).
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            <b>· Accessing and Correcting Your Information.</b> You may contact
            us as provided in Section 12 (How to Contact Us) to request access
            to, correct, or delete any personal information that you have
            provided to us. We may not accommodate a request to change
            information if we believe the change would violate any law or legal
            requirement or cause the information to be incorrect.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We do not control third parties' collection or use of your
            information to serve interest-based advertising. However, these
            third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt-out of
            receiving targeted ads from members of the Network Advertising
            Initiative ("NAI") on the NAI's website, https://thenai.org/opt-out.
            Similarly, you can opt-out of receiving targeted ads from members of
            the Digital Advertising Alliance ("DAA") on the DAA's website,
            https://www.privacyrights.info.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            8. Security
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We implement various physical, administrative, and technical
            safeguards in accordance with industry standards to protect the
            security of your personal information both online and offline. The
            safety and security of your information also depends on you. Where
            you have login information, including a password, for access to the
            link which provides access to certain parts of the Services, you are
            responsible for keeping this password confidential. Do not share
            your password with anyone.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to the Services. Any transmission
            of personal information is at your own risk. We are not responsible
            for circumvention of any privacy settings or security measures
            contained on the Services.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            9. Children's Privacy
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            The Services are not directed to nor intended for use by children
            under 18 years old ("Prohibited Users"). We do not knowingly collect
            personal information from Prohibited Users. Prohibited Users are not
            authorized to use the Services. If you are a Prohibited User, please
            do not provide any personal information to, on, through the
            Services. If we learn we have collected or received personal
            information from a Prohibited User, we will delete that information.
            If you believe we might have any information from or about a
            Prohibited User, please contact us by any of the methods listed in
            Section 12 (How to Contact Us).
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            10. Changes to this Policy
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            We reserve the right to review and revise this Policy from time to
            time, without prior notice, subject to applicable legal requirements
            to notify you or obtain your consent. The date this Policy was last
            reviewed or revised is noted at the top of the page. You are
            responsible for periodically visiting this Site and this Policy to
            check for any changes. Your continued use of the Services following
            the posting of changes constitutes your acceptance of such changes.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2, fontWeight: "bold" }}>
            11. Notice for Nevada Residents
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            This Section 11 applies exclusively to visitors and users of the
            Services who reside in the state of Nevada. Nevada provides its
            residents with a limited right to opt-out of certain personal
            information sales. Nevada residents who wish to exercise this sale
            opt-out right may email us at support@benchconsulting.com. Please
            include "Nevada" in your email subject line and include the
            following information in your email: (i) your name, (ii) Nevada
            resident address, and (iii) email address. However, please know we
            do not currently sell data triggering that statute's opt-out
            requirements.
          </Typography>
          <Typography sx={{ textAlign: "justify", m: 2 }}>
            12. How to Contact Us To exercise your privacy options, ask
            questions, or comment about this Policy and our privacy practices,
            you may contact us:
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Via mail at 223 W Main St, Suite B2 Charlottesville, VA 22902
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Via phone at 410-562-4591
          </Typography>
          <Typography sx={{ textAlign: "justify", ml: 5, mr: 5, mb: 2 }}>
            · Via email at support@benchconsulting.com
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
