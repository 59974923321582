export const signIn = (payload: any) => ({
  type: "SIGN_IN",
  payload,
});

export const signOut = () => ({
  type: "SIGN_OUT",
});

export const updateUser = (payload: any) => ({
  type: "UPDATE_USER",
  payload,
});
