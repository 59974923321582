import { actionType } from "./index";

const initialState: any[] = [];

export default (state = initialState, { type, payload }: actionType) => {
  switch (type) {
    case "UPDATE_ROLES_LIST":
      return payload;
    default:
      return state;
  }
};
