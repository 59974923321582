import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import {
  Container,
  TextArea,
  TextField,
  axiosInstance,
  errorHandler,
  isSameObject,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setPreventBack } from "../../../../redux/actions/settings";
import { RootState } from "../../../../redux/store";

export interface EmailResponse {
  UUID: string;
  email: Email;
  result: string;
}

export interface Email {
  id: string;
  subject: string;
  body: string;
}

const validationSchema = yup.object({
  subject: yup.string().required("errors.required_field"),
  body: yup.string().required("errors.required_field"),
});

export default function EmailTemplate() {
  const [initialValues, setInitialValues] = useState<Email>({
    subject: "",
    body: "",
    id: "",
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { preventBack } = useSelector((state: RootState) => state.settings);

  const getEmailData = async () => {
    setLoading(true);
    const { data: res } = await axiosInstance.get<EmailResponse>(
      "email-template",
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      }
    );
    setInitialValues({
      id: res.email.id,
      subject: res.email.subject,
      body: res.email.body,
    });
    setLoading(false);
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await axiosInstance.put(`email-template/${initialValues.id}`, {
          id: initialValues.id,
          subject: values.subject,
          body: values.body,
        });
        dispatch(setPreventBack(false));
        setTimeout(() => {
          enqueueSnackbar(t("admin.email.email_template_saved"), {
            variant: "success",
          });
          history.push("/admin/email-template");
        }, 200);
        setSubmitting(false);
      } catch (e) {
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });

  useEffect(() => {
    if (!form.isSubmitting) {
      if (
        !isSameObject(form.values, form.initialValues) &&
        preventBack === false
      ) {
        dispatch(setPreventBack(true));
      } else if (
        isSameObject(form.values, form.initialValues) &&
        preventBack === true
      )
        dispatch(setPreventBack(false));
    }
  }, [form.values]);

  useEffect(
    () => () => {
      dispatch(setPreventBack(false));
    },
    []
  );

  useEffect(() => {
    getEmailData();
  }, []);

  if (loading) {
    return (
      <Container title="Email Template">
        <Box sx={{ display: "flex", height: 300 }}>
          <CircularProgress sx={{ margin: "auto" }} />
        </Box>
      </Container>
    );
  }
  return (
    <Container
      title="Email Template"
      actions={[
        {
          title: "Save",
          disabled: loading || form.isSubmitting || !form.isValid,
          permissionKeys: ["admin.email.general.can_update"],
          onClick: () => form.submitForm(),
        },
      ]}
    >
      <Grid container sx={{ p: 4 }}>
        <Grid xs={12} sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: 2 }}>{t("general.subject")}:</Typography>
          <TextField name="subject" form={form} fullWidth />
        </Grid>
        <Grid xs={12} sx={{ mt: 2 }}>
          <Typography sx={{ mb: 1 }}>{t("general.body")}:</Typography>
          <TextArea
            form={form}
            name="body"
            sx={{
              textAlign: "justify",
              lineHeight: "30px",
              wordSpacing: "1px",
              minHeight: "500px",
              height: "500px",
              maxWidth: "1500px",
              padding: 40,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
