import { Add } from "@mui/icons-material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { accountsContext } from "@neostella/neojumpstart-accounts-frontend";
import {
  DataGrid,
  PermissionWrapper,
  axiosInstance,
  errorHandler,
} from "@neostella/neostella-ui";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

interface SurveysByAccountIDResp {
  UUID: string;
  count: number;
  records: Record[];
  result: string;
}

interface Record {
  survey_id: string;
  is_template: boolean;
  name: string;
  is_active: boolean;
  status_type_id: string;
  status_type_name: string;
  created_by: string;
  created_by_name: string;
  updated_by: null | string;
  updated_by_name: null | string;
  created_at: string;
  updated_at: string | null;
}

export default function AccountSurveys() {
  const { setContainerButton } = useContext(accountsContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [surveysList, setSurveysList] = useState<
    | {
        id: string;
        name: string;
        status: string;
        created_by: string;
        created_at: Date | string;
      }[]
    | null
  >([]);
  const { id } = useParams<any>();
  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    textAlign: "center",
    p: 4,
  };

  const createPopUp = () => (
    <Modal open={open}>
      <Paper sx={style}>
        <Typography variant="h5">{t("accounts.createPopUpText")}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => history.push(`/accounts/${id}/survey/create`)}
            sx={{ m: 2 }}
            fullWidth
          >
            {t("general.no")}
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              history.push(`/accounts/${id}/survey/select_template`)
            }
            sx={{ m: 2 }}
            fullWidth
          >
            {t("general.yes")}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
  const getSurveys = async () => {
    try {
      setSurveysList(null);
      const { data: res } = await axiosInstance.get<SurveysByAccountIDResp>(
        `surveys?account_id=${id}`
      );
      setSurveysList(
        res.records.map((s) => ({
          id: s.survey_id,
          name: s.name,
          status: s.status_type_name,
          created_by: s.created_by_name,
          created_at: s.created_at ? new Date(s.created_at) : "",
        }))
      );
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    getSurveys();
    setContainerButton([
      {
        title: t("surveys.create_survey"),
        titleIcon: <Add />,
        onClick: () => {
          setOpen(true);
        },
        permissionKeys: ["account.survey.general.can_create"],
      },
    ]);
    return () => {
      setContainerButton([]);
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: t("general.actions"),
      flex: 1,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <PermissionWrapper
            permissionKeys={["account.survey.general.can_update"]}
            type="hide"
          >
            <Tooltip title="Edit Survey">
              <IconButton
                onClick={() =>
                  history.push(`/accounts/${id}/survey/edit/${params.id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </PermissionWrapper>
          <PermissionWrapper
            permissionKeys={["account.survey.user_survey.can_create"]}
            type="hide"
          >
            <Tooltip title="Reporting">
              <IconButton
                onClick={() =>
                  history.push(`/accounts/${id}/survey/${params.id}/report/1`)
                }
              >
                <SummarizeIcon />
              </IconButton>
            </Tooltip>
          </PermissionWrapper>
          <PermissionWrapper
            permissionKeys={["account.survey.user_survey.can_create"]}
            type="hide"
          >
            <Tooltip title="Send Survey">
              <IconButton
                onClick={() =>
                  history.push(`/accounts/${id}/survey/send/${params.id}`)
                }
              >
                <ForwardToInboxIcon />
              </IconButton>
            </Tooltip>
          </PermissionWrapper>
        </>
      ),
    },
    {
      field: "name",
      headerName: t("general.name"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("general.status"),
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("general.created_by"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("general.created_at"),
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? format(params.value, "MM/dd/yyyy hh:mm aaaaa'm'") : "",
    },
  ];

  return (
    <>
      <DataGrid
        sx={{ m: 4, minHeight: 440 }}
        quickFilter
        disableSelectionOnClick
        columns={columns}
        rows={surveysList || []}
        loading={surveysList === null}
        extraProps={{
          autoHeight: surveysList && surveysList?.length > 0,
        }}
      />

      {createPopUp()}
    </>
  );
}
