import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import account from "./reducers/account";
import alerts from "./reducers/alerts";
import customTranslations from "./reducers/customTranslations";
import modules from "./reducers/modules";
import permissions from "./reducers/permissions";
import roles from "./reducers/roles";
import settings from "./reducers/settings";
import user from "./reducers/user";

const reducers = combineReducers({
  user,
  settings,
  customTranslations,
  roles,
  permissions,
  modules,
  alerts,
  account,
});

export type RootState = ReturnType<typeof reducers>;

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = createStore(reducers, composedEnhancers);

export default store;
