import { PermissionWrapper } from "@neostella/neostella-ui";
import { Route, Switch } from "react-router-dom";
import EmailTemplate from "./EmailTemplate";

export default function EmailTemplateRouter() {
  return (
    <Switch>
      <Route path="/admin/email-template">
        <PermissionWrapper
          permissionKeys={["admin.user_group.general.can_update"]}
          type="hide"
          redirect="/admin"
        >
          <EmailTemplate />
        </PermissionWrapper>
      </Route>
    </Switch>
  );
}
