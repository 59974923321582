interface ThemePayload {
  themeMode: string;
  themeStyle: string;
}
interface CurrentThemePayload {
  currentThemeMode: string;
}

export const toggleDrawer = () => ({
  type: "TOGGLE_DRAWER",
});

export const setTenant = (payload: any) => ({
  type: "SET_TENANT",
  payload,
});

export const setLanguage = (payload: string) => {
  localStorage.setItem("lang", payload);
  return {
    type: "SET_LANGUAGE",
    payload,
  };
};

export const setTheme = (payload: ThemePayload) => {
  localStorage.setItem("themeMode", payload.themeMode);
  localStorage.setItem("themeStyle", payload.themeStyle);
  return {
    type: "SET_THEME",
    payload,
  };
};
export const setCurrentTheme = (payload: CurrentThemePayload) => ({
  type: "SET_CURRENT_THEME",
  payload,
});

export const setPreventBack = (payload: boolean) => ({
  type: "PREVENT_BACK",
  payload,
});
