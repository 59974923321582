import { Box, Button } from "@mui/material";
import {
  AutoComplete,
  axiosInstance,
  Container,
  errorHandler,
  TextField,
} from "@neostella/neostella-ui";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setPreventBack } from "../../../../redux/actions/settings";

const validationSchema = yup.object({
  category_name: yup.string().required("errors.required_field"),
  parent_category: yup.string(),
});

export default function CreateCategory() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [parentCategories, setParentCategories] = useState<
    {
      value: string;
      label: string;
    }[]
  >([{ value: "", label: "" }]);
  const createForm = useFormik({
    validationSchema,
    initialValues: {
      category_name: "",
      parent_category: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await axiosInstance.post("categories", {
          category_name: values.category_name,
          parent_category: values.parent_category || null,
        });
        dispatch(setPreventBack(false));
        setTimeout(() => {
          enqueueSnackbar(t("admin.categories.category_created"), {
            variant: "success",
          });
          history.push("/admin/categories");
        }, 200);
      } catch (e) {
        errorHandler(e, enqueueSnackbar, t);
      }
    },
  });

  const getParentCategories = async () => {
    const { data: res } = await axiosInstance.get("categories?limit_deep=true");
    setParentCategories(
      res.records.map((pc: any) => ({
        value: pc.category_id ? pc.category_id : "",
        label: pc.name.split("/").slice(-1).join(),
      }))
    );
  };

  useEffect(() => {
    getParentCategories();
  }, []);

  return (
    <Container title="Create Category">
      <Box sx={{ p: 4 }} component="form" onSubmit={createForm.handleSubmit}>
        <TextField
          form={createForm}
          margin="none"
          fullWidth
          label={t("admin.categories.category_name")}
          name="category_name"
          type="text"
          noAutocomplete
          sx={{ mb: 2 }}
        />
        <AutoComplete
          options={parentCategories}
          disableClearable
          getOptionLabel={(option: string) => {
            const label = parentCategories.find(
              (c: { value: string; label: string }) => c.value === option
            )?.label;

            return label || "";
          }}
          onChange={(e, v) =>
            createForm.setFieldValue("parent_category", v.value)
          }
          extraProps={{
            filterOptions: (
              options: { label: string; value: string }[],
              state: { inputValue: string }
            ) => {
              const searchResult: any = [];
              options.forEach((option) => {
                if (
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                ) {
                  searchResult.push(option);
                }
              });
              return searchResult;
            },
          }}
          name="parent_category"
          form={createForm}
          label={t("admin.categories.parent_category")}
          sx={{ mb: 3 }}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ flex: 1, m: 1 }}
            variant="contained"
            onClick={() => history.push("/admin/categories")}
          >
            {t("general.cancel")}
          </Button>
          <Button
            sx={{ flex: 1, m: 1 }}
            variant="contained"
            type="submit"
            disabled={createForm.isSubmitting || !createForm.isValid}
          >
            {t("general.create")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
