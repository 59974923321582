import { Add, Edit as EditIcon } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-premium";
import {
  axiosInstance,
  Container,
  DataGrid,
  errorHandler,
  PermissionWrapper,
} from "@neostella/neostella-ui";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPreventBack } from "../../../../redux/actions/settings";

interface GroupsResp {
  UUID: string;
  count: number;
  records: Group[];
  result: string;
}

interface Group {
  user_group_id: string;
  name: string;
  is_active: boolean;
  created_by: string;
  created_by_name: string;
  updated_by: null | string;
  updated_by_name: null | string;
  created_at: string | null;
  updated_at: string | null;
}

export default function Groups() {
  const [t] = useTranslation();
  const history = useHistory();
  const [groups, setGroups] = useState<Group[] | null>(null);
  const [queryFilter, setQueryFilter] = useState("enabled");
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getGroups = async () => {
    try {
      setGroups(null);
      const { data: res } = await axiosInstance.get<GroupsResp>(
        `usergroups?is_active=${queryFilter}`
      );
      setGroups(res.records);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    getGroups();
  }, [checked]);
  useEffect(
    () => () => {
      dispatch(setPreventBack(false));
    },
    []
  );

  const callInactiveCategories = () => {
    if (!checked) {
      setQueryFilter("all");
    } else {
      setQueryFilter("enabled");
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    callInactiveCategories();
  };

  const columns: GridColumns = [
    {
      field: "actions",
      headerName: t("general.actions"),
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <PermissionWrapper
          permissionKeys={["admin.user_group.general.can_update"]}
        >
          <IconButton
            onClick={() => history.push(`/admin/groups/edit/${params.id}`)}
          >
            <EditIcon />
          </IconButton>
        </PermissionWrapper>
      ),
    },
    {
      field: "name",
      headerName: t("general.name"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("general.status"),
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("general.created_by"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("general.created_at"),
      flex: 1,
      type: "dateTime",
      valueFormatter: (params) =>
        params.value ? format(params.value, "MM/dd/yyyy hh:mm aaaaa'm'") : "",
    },
  ];

  return (
    <Container
      title={t("admin.groups.groups")}
      actions={[
        {
          title: t("admin.groups.create_group"),
          titleIcon: <Add />,
          onClick: () => history.push("/admin/groups/create"),
          permissionKeys: ["admin.user_group.general.can_create"],
        },
      ]}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: 300,
            m: 4,
          }}
        >
          <Checkbox checked={checked} onChange={handleChangeCheckbox} />
          <Typography>{t("admin.show_inactive_groups")}</Typography>
        </Box>
        <DataGrid
          sx={{ minHeight: 400, m: 4 }}
          disableSelectionOnClick
          quickFilter
          columns={columns}
          rows={
            groups
              ? groups.map((g) => ({
                  id: g.user_group_id,
                  name: g.name,
                  status: g.is_active
                    ? t("general.active")
                    : t("general.inactive"),
                  created_by: g.created_by_name,
                  created_at: g.created_at ? new Date(g.created_at) : "",
                }))
              : []
          }
          loading={groups === null}
        />
      </Box>
    </Container>
  );
}
