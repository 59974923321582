import { Box, CircularProgress, useTheme } from "@mui/material";
import { errorHandler } from "@neostella/neostella-ui";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import usePresignedUrls, { baseURL } from "../../../../hooks/usePresignedUrls";
import {
  Element,
  SurveyData,
} from "../../Accounts/Surveys/AccountSurveysCreateFromTemplate";

interface SurveyInstanceResp {
  UUID: string;
  survey_instance: SurveyInstance | null;
  result: string;
  code?: string;
}

interface SurveyInstance {
  survey: SurveyInfo;
  survey_progress: SurveyProgress;
  user_groups: string[];
}

interface SurveyInfo {
  survey_id: string;
  name: string;
  survey_data?: SurveyData;
  is_active: boolean;
  status_type_id: string;
  status_type_name: string;
  created_at: string;
  updated_at: string;
}

interface SurveyProgress {
  client_survey_id: string;
  cognito_user_id: string;
  created_at: string | null;
  current_page: number;
  full_name: string;
  status_type_id: string;
  status_type_name: string;
  survey_result?: object;
  updated_at: string | null;
}

interface SurveyInstanceUpdateResp {
  UUID: string;
  client_survey_id: string;
  result: string;
}

export default function SurveyRunner() {
  const { survey_id } = useParams<{ survey_id: string }>();
  const theme = useTheme();
  const history = useHistory();
  const { mode } = theme.palette;
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [surveyData, setSurveyData] = useState<null | SurveyInstance>(null);
  const { getPresignedUrl, fetchSurveyData, saveSurveyData } =
    usePresignedUrls();

  const getSurvey = async () => {
    try {
      const { data } = await axios.get<SurveyInstanceResp>(
        `${baseURL}/surveyinstance/${survey_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "public",
          },
          data: {},
        }
      );

      if (
        data.survey_instance === null ||
        data.code === "SurveyInstanceGet.SurveyCompleted"
      ) {
        history.push("/p/survey-completed");
      } else {
        /* Survey Data */
        const presignedUrlSurveyGET = await getPresignedUrl(
          "get_object",
          `surveys/${data.survey_instance?.survey.survey_id}.json`
        );
        const surveyDataJson = await fetchSurveyData<SurveyData>(
          presignedUrlSurveyGET.presigned_url.url
        );

        /* Result Data */
        const presignedUrlResultGET = await getPresignedUrl(
          "get_object",
          `result/${data.survey_instance?.survey.survey_id}/${data.survey_instance?.survey_progress.cognito_user_id}.json`
        );
        const resultDataJson = await fetchSurveyData<SurveyData>(
          presignedUrlResultGET.presigned_url.url
        );

        /* Filters questions for the group of the user */
        if (surveyDataJson.pages.length > 0) {
          surveyDataJson.pages.forEach((page) => {
            const elem: Element[] = [];
            if (page.elements) {
              page.elements.forEach((element) => {
                let is_user_group = false;
                element.groups?.forEach((group) => {
                  if (data.survey_instance?.user_groups.includes(group)) {
                    is_user_group = true;
                  }
                });
                if (is_user_group) {
                  elem.push(element);
                }
              });
            }
            page.elements = elem;
          });
          surveyDataJson.pages = surveyDataJson.pages.filter(
            (page) => page.elements.length !== 0
          );
        }
        const newData: SurveyInstance = {
          ...data.survey_instance,
          survey: {
            ...data.survey_instance.survey,
            survey_data: surveyDataJson,
          },
          survey_progress: {
            ...data.survey_instance.survey_progress,
            survey_result: resultDataJson,
          },
        };
        setSurveyData(newData);
      }
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  const survey = new Model(surveyData?.survey?.survey_data);

  const loadState = (_survey: Model) => {
    if (surveyData?.survey_progress.survey_result) {
      _survey.data = surveyData?.survey_progress.survey_result;
      _survey.currentPageNo = surveyData?.survey_progress.current_page || 0;
    }
  };

  const saveState = async (_survey: Model, markAsCompleted?: boolean) => {
    try {
      await axios.put<SurveyInstanceUpdateResp>(
        `${baseURL}/surveyinstance`,
        {
          client_survey_id: surveyData?.survey_progress.client_survey_id,
          // survey_result: _survey.data,
          token: survey_id,
          current_page: _survey.currentPageNo,
          status: markAsCompleted ? "complete" : "in progress",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "public",
          },
          data: {},
        }
      );
      const presignedUrlPUT = await getPresignedUrl(
        "put_object",
        `result/${surveyData?.survey.survey_id}/${surveyData?.survey_progress.cognito_user_id}.json`
      );

      await saveSurveyData(_survey.data, survey_id, presignedUrlPUT);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };
  survey.onCurrentPageChanged.add((surv: any) => {
    saveState(surv);
  });

  survey.onComplete.add((surv: any) => {
    saveState(surv, true);
  });

  loadState(survey);

  useEffect(() => {
    getSurvey();
  }, []);

  return surveyData ? (
    <Box sx={{ pb: 3 }} className={`form__container ${mode}`}>
      <Survey model={survey} />
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
