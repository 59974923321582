import { axiosInstance, errorHandler } from "@neostella/neostella-ui";

export const setRoles = (payload: any[]) => ({
  type: "UPDATE_ROLES_LIST",
  payload,
});

export const updateRoles = () => async (dispatch: any) => {
  try {
    const { data } = await axiosInstance.get("roles?data=roles-permissions");
    const value = Array.isArray(data) ? data : [];
    dispatch({
      type: "UPDATE_ROLES_LIST",
      payload: value,
    });
  } catch (e) {
    errorHandler(e, null, null, dispatch);
  }
};
