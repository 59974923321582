import { Add, Edit as EditIcon } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import {
  axiosInstance,
  Container,
  DataGrid,
  errorHandler,
  PermissionWrapper,
} from "@neostella/neostella-ui";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function SurveyTemplates() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [queryFilter, setQueryFilter] = useState("enabled");
  const [checked, setChecked] = useState(false);
  const [surveyTemplateList, setSurveyTemplatesList] = useState<[] | null>([]);

  const getQuestionTemplates = async () => {
    try {
      setSurveyTemplatesList(null);

      const { data: res } = await axiosInstance.get(
        `surveys?is_active=${queryFilter}&is_template=enabled`
      );
      setSurveyTemplatesList(
        res.records.map((s: any) => ({
          id: s.survey_id,
          name: s.name,
          status: s.is_active ? t("general.active") : t("general.inactive"),
          created_by: s.created_by_name,
          created_at: s.created_at ? new Date(s.created_at) : "",
        }))
      );
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };
  const callInactiveQuestionTemplates = () => {
    if (!checked) {
      setQueryFilter("all");
    } else {
      setQueryFilter("enabled");
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    callInactiveQuestionTemplates();
  };

  useEffect(() => {
    getQuestionTemplates();
  }, [checked]);

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: t("general.actions"),
      flex: 1,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <PermissionWrapper
          permissionKeys={["admin.survey_template.general.can_update"]}
        >
          <IconButton
            onClick={() => history.push(`/admin/survey_templates/${params.id}`)}
          >
            <EditIcon />
          </IconButton>
        </PermissionWrapper>
      ),
    },
    {
      field: "name",
      headerName: t("general.name"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("general.status"),
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("general.created_by"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("general.created_at"),
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) =>
        params.value === ""
          ? ""
          : format(params.value, "MM/dd/yyyy hh:mm aaaaa'm'"),
    },
  ];

  return (
    <Container
      title={t("surveys.survey_template")}
      actions={[
        {
          title: t("surveys.create_survey_template"),
          titleIcon: <Add />,
          onClick: () => history.push("/admin/survey_templates/create"),
          permissionKeys: ["admin.survey_template.general.can_create"],
        },
      ]}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: 300,
            m: 4,
          }}
        >
          <Checkbox checked={checked} onChange={handleChangeCheckbox} />
          <Typography>{t("surveys.show_inactive_survey_templates")}</Typography>
        </Box>
        <DataGrid
          sx={{ m: 4, height: 440 }}
          quickFilter
          disableSelectionOnClick
          columns={columns}
          rows={surveyTemplateList || []}
          loading={surveyTemplateList === null}
          extraProps={{
            autoHeight: surveyTemplateList && surveyTemplateList?.length > 0,
          }}
        />
      </Box>
    </Container>
  );
}
