import { axiosInstance, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SurveyCreator } from "survey-creator-react";
import usePresignedUrls from "../../../../hooks/usePresignedUrls";
import { SurveysPostResponse } from "../../Accounts/Surveys/AccountSurveysCreateFromScratch";
import CreateSurveyComponent, {
  PostPayload,
} from "../../Accounts/Surveys/CreateSurveyComponent";

export default function SurveyTemplatesCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [surveyData, setSurveyData] = useState<
    SurveyCreator["JSON"] | null | undefined
  >(undefined);

  const [t] = useTranslation();
  const history = useHistory();
  const { getPresignedUrl, saveSurveyData, isSurveyValid } = usePresignedUrls();

  const createSurvey = async (creator: SurveyCreator) => {
    setIsSubmitting(true);
    const surveyJSON = creator.JSON;
    setSurveyData(surveyJSON);
    const postPayload: PostPayload = {
      name: surveyJSON.title,
      // survey_data: surveyJSON,
      is_template: true,
      status: "draft",
    };
    try {
      if (isSurveyValid(surveyJSON)) {
        const { data } = await axiosInstance.post<SurveysPostResponse>(
          "surveys",
          postPayload
        );
        const presignedUrlPUT = await getPresignedUrl(
          "put_object",
          `surveys/${data.survey_id}.json`
        );

        await saveSurveyData(surveyJSON, data.survey_id, presignedUrlPUT);

        setTimeout(() => {
          enqueueSnackbar(t("surveys.survey_template_created"), {
            variant: "success",
          });

          history.push("/admin/survey_templates");
        }, 200);
        setIsSubmitting(false);
      } else {
        enqueueSnackbar(
          t("errors.verify_all_questions_have_a_valid_category_and_group"),
          {
            variant: "error",
          }
        );
        setIsSubmitting(false);
      }
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
      setIsSubmitting(false);
    }
  };

  return (
    <CreateSurveyComponent
      onSubmit={createSurvey}
      buttonDisabled={isSubmitting}
      surveyData={surveyData}
    />
  );
}
