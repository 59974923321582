import { errorHandler } from "@neostella/neostella-ui";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import type { SurveyData } from "../pages/Dashboard/Accounts/Surveys/AccountSurveysCreateFromTemplate";

export interface PresignedUrlResponse {
  UUID: string;
  presigned_url: PresignedURL;
}

interface PresignedURL {
  url: string;
  fields: Fields | null;
}

interface Fields {
  key: string;
  "x-amz-algorithm": string;
  "x-amz-credential": string;
  "x-amz-date": string;
  "x-amz-security-token": string;
  policy: string;
  "x-amz-signature": string;
}

export const baseURL = `${process.env.REACT_APP_ENDPOINT_URL}/${process.env.REACT_APP_STAGE}`;

export default function usePresignedUrls() {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const getPresignedUrl = async (
    client_method: "put_object" | "get_object",
    object_key: string
  ) => {
    try {
      const { data } = await axios.get<PresignedUrlResponse>(
        `${baseURL}/presigned-url-get/?client_method=${client_method}&object_key=${object_key}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        }
      );

      return data;
    } catch (error) {
      errorHandler(error, enqueueSnackbar, t);
      throw error;
    }
  };

  const fetchSurveyData = async <T>(presigned_url: string) => {
    try {
      const { data } = await axios.get<T>(presigned_url);
      return data;
    } catch (error) {
      errorHandler(error, enqueueSnackbar, t);
      throw error;
    }
  };

  const saveSurveyData = async (
    json: SurveyData,
    currentSurveyId: string,
    urlAndFields: PresignedUrlResponse
  ) => {
    /* Converts json to File */
    const jsonString = JSON.stringify(json);
    const blob = new Blob([jsonString], { type: "application/json" });
    const file = new File([blob], `${currentSurveyId}.json`);

    /* Appends fields and file to FormData */
    const presignedUrlData = {
      url: urlAndFields.presigned_url.url,
      fields: urlAndFields.presigned_url.fields,
    };
    if (presignedUrlData.fields) {
      const formData = new FormData();
      Object.entries(presignedUrlData.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      try {
        await axios.post(urlAndFields.presigned_url.url, formData);
      } catch (error) {
        errorHandler(error, enqueueSnackbar, t);
      }
    }
  };

  const isSurveyValid = (_surveyJSON: SurveyData): boolean => {
    for (const page of _surveyJSON.pages) {
      for (const element of page.elements) {
        // Check if 'categories' exists, is an array, and has at least one string
        if (
          !element.categories ||
          !Array.isArray(element.categories) ||
          element.categories.length === 0
        ) {
          return false;
        }
        // Check if 'groups' exists, is an array, and has at least one string
        if (
          !element.groups ||
          !Array.isArray(element.groups) ||
          element.groups.length === 0
        ) {
          return false;
        }
      }
    }
    return true;
  };

  return { getPresignedUrl, fetchSurveyData, saveSurveyData, isSurveyValid };
}
