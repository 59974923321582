import { actionType } from "./index";

const initialState: any = null;

export default (state = initialState, { type, payload }: actionType) => {
  switch (type) {
    case "UPDATE_MODULES":
      return payload;
    default:
      return state;
  }
};
