import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import LanguageIcon from "@mui/icons-material/Language";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ShieldIcon from "@mui/icons-material/Shield";
import { grey } from "@mui/material/colors";
import { AccountsRouter } from "@neostella/neojumpstart-accounts-frontend";
import {
  AdminRouter,
  RolesRouter,
  TenantKeysRouter,
  TranslationsRouter,
  UserSettingsRouter,
  UsersRouter,
} from "@neostella/neostella-ui";
import { DrawerItem } from "@neostella/neostella-ui/dist/components/Drawer";
import {
  RootRoute,
  RouteProps,
} from "@neostella/neostella-ui/dist/modules/CoreRouter";
import { LanguagesObject } from "@neostella/neostella-ui/dist/modules/translations/TranslationsRouter";
import { Redirect, Route } from "react-router-dom";
import AccountSurveyReport from "./pages/Dashboard/Accounts/Reports/AccountSurveyReport";
import AccountSurveys from "./pages/Dashboard/Accounts/Surveys/AccountSurveys";
import AccountSurveysCreateFromScratch from "./pages/Dashboard/Accounts/Surveys/AccountSurveysCreateFromScratch";
import AccountSurveysCreateFromTemplate from "./pages/Dashboard/Accounts/Surveys/AccountSurveysCreateFromTemplate";
import AccountSurveysEditSurvey from "./pages/Dashboard/Accounts/Surveys/AccountSurveysEditSurvey";
import AccountSurveysSendSurvey from "./pages/Dashboard/Accounts/Surveys/AccountSurveysSendSurvey";
import AccountSurveysTemplateSelector from "./pages/Dashboard/Accounts/Surveys/AccountSurveysTemplateSelector";
import CategoryRouter from "./pages/Dashboard/Admin/Categories/CategoryRouter";
import EmailTemplateRouter from "./pages/Dashboard/Admin/EmailTemplate/EmailTemplateRouter";
import GroupsRouter from "./pages/Dashboard/Admin/Groups/GroupsRouter";
import SurveyTemplatesRouter from "./pages/Dashboard/Admin/SurveyTemplates/SurveyTemplatesRouter";
import { updateRoles } from "./redux/actions/roles";
import { setPreventBack, setTheme } from "./redux/actions/settings";
import { updateUser } from "./redux/actions/user";
import store from "./redux/store";
import enCommon from "./translations/common/en";
import esCommon from "./translations/common/es";

/*
 *The style for the icons in the admin panel
 */
const adminPanelIconStyle = {
  fontSize: 60,
  color: "primary.main",
};
const drawerIconStyle = {
  ml: 1,
  mr: 3,
  color: grey["600"],
};

/*
 *For every language suported in the app, you need to add the language code to the array below.
 */
export const languages: LanguagesObject[] = [
  { code: "en", commonFile: enCommon },
  { code: "es", commonFile: esCommon },
];

/*
 * This is your "/" route. It does not have permissions, redirect, etc. It will be your main component.
 * If you don´t have a root route, yo can redirect to any other route like this:
 * children: <Redirect to="/accounts" />,
 * 
 * CAUTION: This route should not requiere any permissions, or you will generate an infinite redirect
 * If you still want to use a protected route as default, you should add a redirect to a route w/o permissions required
 * For example, users/settings
 * so, in dashboardRoutes you should add somehting like this:
 * {
    path: "/accounts",
    permissionKeys: ["account"],
    type: "hide",
    redirect: "/user/settings",   <------- this will prevent the infinite redirect
    children: ...
 */
export const rootRoute: RootRoute = {
  children: <Redirect to="/accounts" />,
};

/*
 *For every route in the drawer, you need to add the following props:
 */
export const dashboardRoutes: RouteProps[] = [
  {
    path: "/admin",
    permissionKeys: ["admin"],
    type: "hide",
    redirect: "/",
    children: (
      <AdminRouter
        setPreventBack={setPreventBack}
        RootState={store}
        languagesObjects={languages}
        /*
         *adminPanelCards are the items that will be displayed in the admin panel
         */
        adminPanelCards={[
          {
            route: "/admin/languages",
            icon: <LanguageIcon sx={adminPanelIconStyle} />,
            text: "admin.system_languages",
            permissionKeys: ["admin.translations"],
            type: "hide",
            children: (
              <TranslationsRouter
                RootState={store}
                languagesObjects={languages}
              />
            ),
          },
          {
            route: "/admin/users",
            icon: <PeopleAltIcon sx={adminPanelIconStyle} />,
            text: "general.users",
            permissionKeys: ["admin.users"],
            type: "hide",
            children: (
              <UsersRouter RootState={store} setPreventBack={setPreventBack} />
            ),
          },
          {
            route: "/admin/roles",
            icon: <AdminPanelSettingsIcon sx={adminPanelIconStyle} />,
            text: "general.roles",
            permissionKeys: ["admin.roles"],
            type: "hide",
            children: (
              <RolesRouter updateRoles={updateRoles} RootState={store} />
            ),
          },
          {
            route: "/admin/tenant_keys",
            icon: <KeyIcon sx={adminPanelIconStyle} />,
            text: "general.tenant_keys",
            permissionKeys: ["admin.tenant_keys.general.can_read"],
            type: "hide",
            children: <TenantKeysRouter />,
          },
          {
            route: "/admin/survey_templates",
            icon: <ArticleIcon sx={adminPanelIconStyle} />,
            text: "surveys.survey_template",
            permissionKeys: ["admin.survey_template.general.can_read"],
            type: "hide",
            children: <SurveyTemplatesRouter />,
          },
          {
            route: "/admin/categories",
            icon: <CategoryIcon sx={adminPanelIconStyle} />,
            text: "general.categories",
            permissionKeys: ["admin.categories.general.can_read"],
            type: "hide",
            children: <CategoryRouter />,
          },
          {
            route: "/admin/groups",
            icon: <GroupsIcon sx={adminPanelIconStyle} />,
            text: "admin.groups.groups",
            permissionKeys: ["admin.user_group.general.can_read"],
            type: "hide",
            children: <GroupsRouter />,
          },
          {
            route: "/admin/email-template",
            icon: <MailOutlineIcon sx={adminPanelIconStyle} />,
            text: "Email Template",
            permissionKeys: ["admin.email.general.can_read"],
            type: "hide",
            children: <EmailTemplateRouter />,
          },
        ]}
      />
    ),
  },
  {
    path: "/user",
    permissionKeys: ["user_settings"],
    type: "hide",
    redirect: "/",
    children: (
      <UserSettingsRouter
        setTheme={setTheme}
        updateUser={updateUser}
        RootState={store}
        languages={languages.map((l) => l.code)}
      />
    ),
  },
  {
    path: "/accounts",
    permissionKeys: ["account"],
    type: "hide",
    redirect: "/user/settings",
    children: (
      <AccountsRouter
        setPreventBack={setPreventBack}
        RootState={store}
        usersEndPointName="benchusers"
        hideDinvyAndSalesforceID
        hideCurrency
        accountContent={[
          {
            content: <AccountSurveys />,
            label: "Surveys",
            permissionKeys: ["account.survey.general.can_read"],
            href: "surveys",
          },
        ]}
        // TODO: key error in account router in http://localhost:3000/accounts/f6e9bb46-046b-4cdf-9a5a-86d5bf1d7068/survey/send/3747a5e2-5abf-40d1-9641-6abbf4a8dff1
        otherRoutes={[
          <Route key={0} path="/accounts/:id/survey/send/:surveyId">
            <AccountSurveysSendSurvey />
          </Route>,
          <Route key={2} path="/accounts/:id/survey/edit/:surveyId">
            <AccountSurveysEditSurvey />
          </Route>,
          <Route key={4} path="/accounts/:id/survey/select_template">
            <AccountSurveysTemplateSelector />
          </Route>,
          <Route key={5} path="/accounts/:id/survey/:surveyId/report/:reportId">
            <AccountSurveyReport />
          </Route>,
          <Route key={3} path="/accounts/:id/survey/create">
            <AccountSurveysCreateFromScratch />
          </Route>,
          <Route key={1} path="/accounts/:id/survey/template/:surveyId">
            <AccountSurveysCreateFromTemplate />
          </Route>,
        ]}
      />
    ),
  },
];

export const drawerItems: DrawerItem[] = [
  {
    route: "/accounts",
    selected: "accounts",
    icon: <BusinessCenterIcon sx={drawerIconStyle} />,
    text: "general.accounts",
    permissionKeys: ["account.general.general.can_create"],
    type: "hide",
  },
  {
    route: "/accounts",
    selected: "accounts",
    icon: <BusinessCenterIcon sx={drawerIconStyle} />,
    text: "general.my_account",
    permissionKeys: ["account.my_account.general.can_read"],
    type: "hide",
    accountIdInRoute: true,
  },
  {
    route: "/admin",
    selected: "admin",
    icon: <ShieldIcon sx={drawerIconStyle} />,
    text: "general.admin",
    permissionKeys: ["admin"],
    type: "hide",
  },
  {
    route: "/user/settings",
    selected: "user",
    icon: <SettingsIcon sx={drawerIconStyle} />,
    text: "general.user_settings",
    permissionKeys: ["user_settings"],
    type: "hide",
  },
];

export const currencies = {
  dev: {
    usd: { label: "USD", value: "d8caf567-20a8-4d98-90eb-599a64cb946e" },
    gpb: { label: "GBP", value: "6c10519e-8eb4-4a3f-9577-18894cd590cd" },
    cop: { label: "COP", value: "2c45c982-90c3-47e8-b598-56bd824e2333" },
    mxn: { label: "MXN", value: "efcba140-2bc2-4b76-a920-98cd50b63124" },
    brl: { label: "BRL", value: "a3ef5b35-1964-42f4-a963-0deb833a6712" },
    eur: { label: "EUR", value: "6f99890f-4354-4c56-bb20-4034e626d331" },
  },
  qa: {
    usd: { label: "USD", value: "25ddcff9-61fc-4539-b664-1d3572d7df28" },
    gpb: { label: "GBP", value: "46893703-ce99-401d-95f7-17dd14884ea5" },
    cop: { label: "COP", value: "9be8f9a6-6d86-40ba-91ed-02c2a7e963e0" },
    mxn: { label: "MXN", value: "14e88df1-24d4-4372-a22c-a5ffc3c5ba90" },
    brl: { label: "BRL", value: "31172a46-3583-4d18-bafe-84f6ddca0cf8" },
    eur: { label: "EUR", value: "d90b3cb4-9b5e-42a7-9c76-65c9c19a15cd" },
  },
};
