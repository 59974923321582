import { GridColDef } from "@mui/x-data-grid-premium";
import { axiosInstance, DataGrid, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export interface ReportsResp {
  UUID: string;
  survey_name: string;
  report: Report[];
  result: string;
}

export interface Report {
  cognito_user_id: string;
  user_name: string;
  question_position: number;
  category: string;
  subcategory: string;
  user_group_name: string;
  question: string;
  answer: number;
  comment: string;
}

export default function OldReportOutput() {
  const [t] = useTranslation();
  const { surveyId } = useParams<any>();
  const [reportsData, setReportsData] = useState<Report[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const getReportData = async () => {
    try {
      const { data: res } = await axiosInstance.get<ReportsResp>(
        `reports/${surveyId}?report_type=1`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        }
      );

      setReportsData(res.report);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };
  useEffect(() => {
    getReportData();
  }, []);
  const columns: GridColDef[] = [
    {
      field: "user_name",
      headerName: t("general.user_name"),
      flex: 1,
    },
    {
      field: "question_position",
      headerName: t("general.q_position"),
      flex: 1,
    },
    {
      field: "category",
      headerName: t("general.category_pathway"),
      flex: 1,
    },
    {
      field: "subcategory",
      headerName: t("general.subcategory_competence"),
      flex: 1,
    },
    {
      field: "user_group_name",
      headerName: t("general.user_group"),
      flex: 1,
    },
    {
      field: "question",
      headerName: t("general.question"),
      flex: 1,
    },
    {
      field: "answer",
      headerName: t("general.answer"),
      flex: 1,
    },
    {
      field: "comment",
      headerName: t("general.comments"),
      flex: 1,
    },
  ];

  return (
    <DataGrid
      sx={{ minHeight: 400, m: 4 }}
      disableSelectionOnClick
      quickFilter
      columns={columns}
      rows={
        reportsData
          ? reportsData.map((r, index) => ({
              id: r.cognito_user_id + index,
              user_name: r.user_name,
              question_position: r.question_position,
              category: r.category,
              subcategory: r.subcategory,
              user_group_name: r.user_group_name,
              question: r.question,
              answer: r.answer,
              comment: r.comment,
            }))
          : []
      }
      loading={reportsData === null}
      extraProps={{
        autoHeight: reportsData && reportsData?.length > 0,
      }}
    />
  );
}
