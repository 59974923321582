const getValues = () => ({
  translation: {
    accounts: {
      account_created: "Account created",
      account_updated: "Account updated",
      account_user_created: "Account User Created",
      account_user_updated: "Account User Updated",
      account_users: "Account Users",
      contracts: "Contracts",
      create_account: "Create Account",
      create_account_user: "Create Account User",
      create_contract: "Create Contract",
      details: "Account Details",
      not_found: "Account Not Found",
      additional_details: "Additional Details",
      pricing_sheet: "Pricing Sheet",
      show_inactive_accounts: "Show Inactive Accounts",
      update_account_details: "Update Account Details",
      update_additional_details: "Update Additional Details",
      createPopUpText: "Would you like to start from a template?",
    },
    admin: {
      email: {
        email_template_saved: "Email Template Saved",
      },
      languages: {
        add_custom_translation: "Add custom translation",
        custom_value: "Custom value",
        customers: {
          create_customer: {
            add_new_shipping_address: "Add new shipping address",
          },
        },
        sure_remove_translation:
          '"{{word}}" will be replaced with "{{default}}"',
        system_value: "System value",
        there_are_no_translations:
          "There are no custom translations for this language",
        update_custom_translation: "Update custom translation",
      },
      roles: {
        create_role: "Create Role",
        edit_role: "Edit role",
        role_created: "Role created",
        role_not_found: "Role not found",
        role_updated: "Role updated",
        show_inactive_roles: "Show Inactive Roles",
        sure_remove_role:
          'Deleting Role: "{{role_name}}" could impact existing system users. Do you want to continue?',
      },
      categories: {
        categories: "Categories",
        create_category: "Create Category",
        category_name: "Category Name",
        parent_category: "Parent Category",
        category_created: "Category Created",
        category_updated: "Category Updated",
        deactivate_parent_category:
          "Deactivating a parent category will deactivate all child categories. Do you want to continue?",
      },
      groups: {
        create_group: "Create Group",
        edit_group: "Edit Group",
        groups: "Groups",
        group_created: "Group Created",
        group_updated: "Group Updated",
      },
      show_inactive_account_users: "Show Inactive Account Users",
      show_inactive_customers: "Show Inactive Customers",
      show_inactive_products: "Show Inactive Products",
      show_inactive_users: "Show Inactive Users",
      show_inactive_contracts: "Show Inactive Contracts",
      show_inactive_question_templates: "Show Inactive Question Templates",
      show_inactive_answer_templates: "Show Inactive Answer Templates",
      show_inactive_categories: "Show Inactive Categories",
      show_inactive_groups: "Show Inactive Groups",
      system_languages: "System Languages",
      time_zone: "Time Zone",
    },
    advanced_search: {
      prefilter: "Prefilter",
      webhooksLogs: {
        action: "Action",
        created: "Created",
        create: "Create",
        created_by: "Created By",
        date: "Date",
        entity: "Entity",
        false: "False",
        full_name: "Full Name",
        insert: "Create",
        is_active: "Is Active",
        order_by: "Order By",
        reference: "Reference",
        sort: "Sort",
        status_code: "Status Code",
        system_event_id: "System Event ID",
        table_name: "Table Name",
        true: "True",
        update: "Update",
        webhook_event_id: "Webhook Event ID",
        webhook_id: "Webhook ID",
        webhook_log_id: "Webhook Log ID",
        webhook_name: "Webhook Name",
        webhooks_logs: "Webhooks Logs",
      },
    },
    authentication: {
      an_email_has_been_sent:
        "A verification code has been sent to {{email}}, please complete the fields below to reset your password",
      are_you_sure_logout: "Are you sure you want to Log Out?",
      back_to_login: "Back to login",
      code_sent: "Code sent",
      create_new_password: "Create new password",
      forgot_password: "Forgot password",
      forgot_your_password: "Forgot your password?",
      new_password: "New password",
      password_resetted:
        "Your password has been successfully changed. You will be redirected to the Login screen",
      please_enter_password_below: "Please enter your new password below",
      please_enter_the_email_you_use_to_login:
        "Please enter the email you use to login to this application",
      repeat_new_password: "Repeat new password",
      repeat_password: "Repeat password",
      reset_password: "Reset password",
      temporary_password_sent: "Temporary password sent",
    },
    errors: {
      add_at_least_one_item: "Add at least one item",
      changes_you_made_may_not_be_saved: "Changes you made may not be saved",
      code_limit_exceeded:
        "You exceeded the limit of codes, try again in a few minutes",
      enter_a_new_value: "Enter a new value",
      enter_code: "Enter the 6 digit code",
      enter_email: "Enter your email",
      enter_password: "Enter your password",
      invalid_date: "Invalid Date",
      invalid_date_range: "Invalid Date Range",
      invalid_email: "Invalid email",
      invalid_email_or_password: "Invalid email or password",
      invalid_phone: "Invalid phone",
      invalid_ssn: "Invalid SSN",
      not_found: "Not found",
      number_must_be_positive_integer: "Number must be a positive integer",
      object_limit_reached: "Limit reached",
      order_name_already_exists: "Order name alredy exists",
      password_no_match: "Passwords don't match",
      password_requirements:
        "Must contain at least 8 characters, one uppercase, one lowercase, one number, one symbol",
      required_field: "Required field",
      role_no_access: "Role does not have access to this feature",
      select_a_value: "Select a value",
      select_at_least_one_permission: "Select at least one permission",
      select_at_least_one_role: "Select at least one role",
      something_went_wrong: "Something went wrong",
      survey_name_already_exist: "Survey Name Already Exist",
      survey_page_no_empty: "Survey Can't Have Empty Pages",
      tenant_no_access: "Tenant does not have access to this feature",
      tenant_not_found: "Tenant not found",
      user_already_exists: "User already exists",
      verify_all_questions_have_a_valid_category_and_group:
        "Verify all questions have a valid category and group",
      verify_all_questions_have_a_valid_group:
        "Verify all questions have a valid group",
      write_at_least_one_value: "Write at least one value",
      wrong_code: "The code you entered is invalid",
      you_cant_add_the_same_item_twice_please_edit_the_previous_one:
        "You can't add the same item twice. Please edit the previous one",
      youe_exceeded_the_limit_of_characters:
        "You exceeded the limit of characters",
    },
    general: {
      account: "Account",
      account_id: "Account ID",
      accounts: "Accounts",
      action: "Action",
      actions: "Actions",
      active: "Active",
      add_filter: "Add filter",
      address: "Address",
      admin: "Admin",
      advanced_search: "Advanced Search",
      answer: "Answer",
      answer_template: "Answer Template",
      body: "Body",
      category_pathway: "Category/Pathway",
      created_by: "Created By",
      created_at: "Created At",
      can_create: "Create",
      can_delete: "Delete",
      can_read: "Read",
      can_update: "Update",
      cancel: "Cancel",
      categories: "Categories",
      choose_a_filter: "Choose a filter",
      choose_a_value: "Choose a value",
      city: "City",
      code: "Verification code",
      comments: "Comments",
      component: "Component",
      conditions: "Conditions",
      configuration: "Configuration",
      continue: "Continue",
      contract: "Contract",
      contract_created: "Contract Created",
      contract_updated: "Contract Updated",
      county: "County",
      create: "Create",
      create_product: "Create Product",
      create_time: "Create Time",
      create_user: "Create User",
      create_question_template: "Create Question Template",
      create_answer_template: "Create Answer Template",
      currency: "Currency",
      custom: "Custom",
      custom_code: "Custom Code",
      customer_name: "Customer Name",
      customers: "Customers",
      dashboard: "Dashboard",
      date: "Date",
      default: "Default",
      default_price: "Default Price",
      delete: "Delete",
      description: "Description",
      dinvy_id: "DinvyID",
      disabled: "Disabled",
      edit: "Edit",
      edit_user: "Edit User",
      email: "Email",
      en: "English",
      enabled: "Enabled",
      es: "Spanish",
      false: "False",
      filters: "Filters",
      first_name: "First Name",
      full_name: "Full Name",
      from: "From",
      function: "Function",
      general: "General",
      global_order_search: "Global Order Search",
      go: "Go",
      go_back: "Go back",
      group_name: "Group Name",
      group: "Group",
      groups: "Groups",
      he: "He/Him",
      hyphen: "Hyphen",
      i_accept_the_terms_and_conditions: "I Accept The Terms And Conditions",
      id: "ID",
      inactive: "Inactive",
      jr: "Jr.",
      language: "Language",
      last_name: "Last Name",
      line_one: "Line 1",
      line_two: "Line 2",
      loading_ellipsis: "Loading...",
      login: "Login",
      logout: "Log out",
      logs: "Logs",
      middle_name: "Middle name",
      module: "Module",
      mr: "Mr.",
      mrs: "Mrs.",
      ms: "Ms.",
      my_account: "My Account",
      name: "Name",
      new_value: "New value",
      next: "Next",
      no: "No",
      no_logs_found: "No logs found",
      no_users_found: "No users found",
      not_prorated: "Not Prorated",
      not_prorated_no: "No",
      number: "Number",
      ok: "Ok",
      order: "Order",
      order_list: "Order List",
      orders: "Orders",
      ownership_vesting: "Ownership Vesting",
      parcel: "Parcel ID",
      password: "Password",
      permissions: "Permissions",
      phone: "Phone",
      pick_a_date: "Pick a Date",
      prefilter_to_get_data: "Prefilter to get data",
      prefix: "Prefix",
      price: "Price",
      price_brl: "Price (BRL)",
      price_cop: "Price (COP)",
      price_eur: "Price (EUR)",
      price_gbp: "Price (GBP)",
      price_mxn: "Price (MXN)",
      price_updated: "Price Updated",
      price_usd: "Price (USD)",
      pricing_sheets: "Pricing Sheets",
      product_code: "Product Code",
      product_created: "Product Created",
      product_description: "Product Description",
      product_name: "Product Name",
      product_price: "Product Price",
      product_type: "Product Type",
      product_updated: "Product Updated",
      products: "Products",
      pronoun: "Pronoun",
      prorated: "Prorated",
      prorated_yes: "Yes",
      question: "Question",
      question_template: "Question Template",
      q_position: "Q Position",
      quantity: "Quantity",
      report: "Report",
      reporting: "Reporting",
      required: "Required",
      resend_code: "Resend Code",
      resend_temporary_password: "Resend temporary password",
      roles: "Roles",
      salesforce_id: "SalesforceID",
      save: "Save",
      search: "Search",
      search_a_webhook: "Search a Webhook",
      select: "Select",
      select_custom_theme: "Select Custom Theme",
      send: "Send",
      she: "She/Her",
      signature_line: "Signature Line",
      sku: "SKU",
      sr: "Sr.",
      ssn: "Social Security Number (SSN)",
      state: "State",
      state_abbreviation: "State Abbreviation",
      status: "Status",
      subcategory_competence: "Subcategory/Competence",
      subcomponent: "Sub-Component",
      subject: "Subject",
      subtotal: "Subtotal",
      success: "Success!",
      suffix: "Suffix",
      survey: "Survey",
      survey_template: "Survey Template",
      system_settings: "System Settings",
      tax_id: "Tax ID",
      tenant_keys: "Tenant Keys",
      thank_you: "Thank you!",
      themeStyle: { light: "Light", dark: "Dark" },
      theme_options: { inherited: "Inherited from browser", custom: "Custom" },
      theme_preferences: "Theme Preferences",
      they: "They/Them",
      to: "To",
      translations: "Translations",
      true: "True",
      update: "Update",
      updated_at: "Updated At",
      updated_by: "Updated By",
      url: "URL",
      user_name: "User Name",
      user_created: "User created",
      user_group: "User Group",
      user_group_id: "User Group ID",
      user_group_account_id: "User Group Account ID",
      user_settings: "User Settings",
      user_survey: "User survey",
      user_updated: "User updated",
      users: "Users",
      value: "Value",
      version: "Version",
      view_report: "View Report",
      work_phone: "Work Phone",
      webhooks: "Webhooks",
      yes: "Yes",
      your_search_returned_no_results: "Your search returned no results",
      zip_code: "ZIP",
    },
    legal: {
      therms_and_conditions_order_submit:
        'You agree that by clicking "Accept" that: (1) you are entering into a binding agreement on behalf of the organization listed above ("Customer") for the purchase(s) described on this page (this "Order") with Neostella, LLC ("Neostella");  (2) you have the authority to enter into this Order on behalf of Customer; (3) the purchase of professional services in this Order (if any) is governed solely by that Professional Services Agreement in effect between Customer and Neostella; and (4) the purchase of Workato Licenses in this Order (if any) are added to the Quote that was executed between Customer and Neostella for the resale of certain Workato, Inc. products, which is governed solely by the terms and conditions referenced therein. Customer also agrees that Orders are non-cancelable, except that Neostella may cancel any Order that it reasonably believes was entered into in error, of which it will promptly notify Customer. ',
    },
    loans: {
      loan: "Loan",
      loan_number: "Loan Number",
      loans: "Loans",
    },
    orders: {
      account_name: "Account Name",
      account_number: "Account Number",
      add_item: "Add Item",
      add_to_cart: "Add to cart",
      address_after_closing: "Address After Closing",
      address_before_closing: "Address Before Closing",
      audit_trail_create_user: "Create User",
      audit_trail_created: "Created Date",
      choose_a_product: "Choose A Product",
      configure_your_product: "Configure Your Product",
      contract_end: "Contract End",
      contract_name: "Contract Name",
      contract_start: "Contract Start",
      create_order: "Create Order",
      end_date: "End Date",
      item_removed_successfully: "Item Removed Successfully",
      item_updated_successfully: "Item Updated Successfully",
      no_orders: "There are no orders",
      no_orders_found: "No orders found",
      order: "Order",
      order_created: "Order created",
      order_details: "Order Details",
      order_name: "Order Name",
      order_name_should_be_at_least_3_characters_long:
        "Order Name should be at least 3 characters long",
      order_not_found: "Order not found",
      order_number: "Order Number",
      order_submitted_successfully: "Order Submitted Successfully",
      order_total: "Order Total",
      order_updated_successfully: "Order Updated Successfully",
      product_added_to_the_shopping_cart: "Product Added To The Shopping Cart",
      property_address_city: "Address - City",
      property_address_county: "Address - County",
      property_address_line_1: "Address - Line 1",
      property_address_state_abbreviation: "Address - State Abbreviation",
      property_address_zip: "Address - ZIP",
      quick_access: "Quick Access",
      remove_item: "Remove item",
      same_as_before_closing: "Same as Before Closing",
      same_as_property_address: "Same as Property Address",
      shopping_cart: "Shopping Cart",
      start_date: "Start Date",
      submit_order: "Submit Order",
      summary: "Summary",
      the_shopping_cart_is_empty: "The Shopping Cart Is Empty",
      this_will_remove_the_product_from_the_shopping_cart_Would_you_like_to_proceed:
        "This will remove the product from the shopping cart. Would you like to proceed?",
      update_date: "Update Date",
      update_product: "Update Product",
      updating_the_order_dates_could_impact_prorated_product_prices_do_you_want_to_proceed:
        "Updating the order dates could impact prorated product prices. Do you want to proceed?",
    },
    surveys: {
      create_survey: "Create Survey",
      create_survey_template: "Create Survey Template",
      group_saved_successfully: "Group Saved Successfully",
      send_survey: "Send Survey",
      show_inactive_survey_templates: "Show Inactive Survey Templates",
      survey_completed: "Survey Completed",
      survey_completed_message:
        "This survey has been completed.  Thank you for your response!",
      survey_created: "Survey Created",
      survey_sent_successfully: "Survey Sent Successfully",
      survey_template: "Survey Template",
      survey_template_created: "Survey Template Created",
      survey_template_updated: "Survey Template Updated",
      survey_updated: "Survey Updated",
      save_as_template: "Save as Template",
      saved_as_template: "Saved as Template",
    },
    tenant_keys: {
      create_tenant_key: "Create Tenant Key",
      tenant_key_created: "Tenant Key Created",
      tenant_keys: "Tenant Keys",
    },
  },
});
export default getValues;
