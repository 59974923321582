import { PermissionWrapper } from "@neostella/neostella-ui";
import { Route, Switch } from "react-router-dom";
import Groups from "./Groups";
import GroupsCreate from "./GroupsCreate";
import GroupsEdit from "./GroupsEdit";

export default function GroupsRouter() {
  return (
    <Switch>
      <Route path="/admin/groups/edit/:id">
        <PermissionWrapper
          permissionKeys={["admin.user_group.general.can_update"]}
          type="hide"
          redirect="/admin"
        >
          <GroupsEdit />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/groups/create">
        <PermissionWrapper
          permissionKeys={["admin.user_group.general.can_create"]}
          type="hide"
          redirect="/admin"
        >
          <GroupsCreate />
        </PermissionWrapper>
      </Route>
      <Route path="/admin/groups">
        <PermissionWrapper
          permissionKeys={["admin.user_group.general.can_read"]}
          type="hide"
          redirect="/admin"
        >
          <Groups />
        </PermissionWrapper>
      </Route>
    </Switch>
  );
}
