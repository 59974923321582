import { actionType } from "./index";

interface stateType {
  productsCount: number;
}
const initialState: stateType = {
  productsCount: 0,
};

export default (
  state: stateType = initialState,
  { type, payload }: actionType
) => {
  switch (type) {
    case "SET_PRODUCTS_COUNT":
      return {
        ...state,
        productsCount: payload,
      };
    default:
      return state;
  }
};
