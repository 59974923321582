import { Box, CircularProgress, useTheme } from "@mui/material";
import { axiosInstance } from "@neostella/neostella-ui";
import { appBarHeight } from "@neostella/neostella-ui/dist/components/AppBar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Action, Serializer } from "survey-core";
import { ICreatorOptions } from "survey-creator-core";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import type { SurveyData } from "./AccountSurveysCreateFromTemplate";

export interface PayloadBase {
  name: string;
  // survey_data: any;
  status: "publish" | "cancelled" | "complete" | "draft";
}
export interface PutPayload extends PayloadBase {
  is_active: boolean;
  is_template?: boolean;
  survey_id: string;
}
export interface PostPayload extends PayloadBase {
  is_template: boolean;
  account_id?: string;
}

export interface UsersGroupsResp {
  UUID: string;
  count: number;
  records: Record[];
  result: string;
}

interface Record {
  user_group_id: string;
  name: string;
  is_active: boolean;
  created_by: string;
  created_by_name: string;
  updated_by: null | string;
  updated_by_name: null | string;
  created_at: string;
  updated_at: null | string;
}

interface CreateSurveyComponentProps {
  onSubmit: (creator: SurveyCreator, isTemplate?: boolean) => void;
  surveyData: SurveyData | null;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  readOnly?: boolean;
  saveSurvey?: boolean;
}

export default function CreateSurveyComponent({
  onSubmit,
  surveyData,
  buttonLabel = "general.create",
  buttonDisabled,
  saveSurvey,
  readOnly,
}: CreateSurveyComponentProps) {
  const [t] = useTranslation();
  const [groups, setGroups] = useState([{ value: "", text: "" }]);
  const [categories, setCategories] = useState({ value: "", text: "" });

  const theme = useTheme();
  const { mode } = theme.palette;

  const creatorOptions: ICreatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    readOnly,
  };

  Serializer.addProperty("question", {
    name: "groups",
    displayName: t("general.groups"),
    choices: groups,
    type: "multiplevalues",
    category: "general",
    visibleIndex: 0,
    isRequired: true,
  });
  Serializer.addProperty("question", {
    name: "categories",
    displayName: t("general.categories"),
    choices: categories,
    type: "multiplevalues",
    category: "general",
    visibleIndex: 1,
    isRequired: true,
  });

  const getGroups = async () => {
    const { data: res } = await axiosInstance.get<UsersGroupsResp>(
      "usergroups?is_active=enabled"
    );

    const group = res.records.map((g) => ({
      value: g.user_group_id,
      text: g.name,
    }));
    setGroups(group);
  };

  const getCategories = async () => {
    const { data: res } = await axiosInstance.get(
      "categories?is_active=enabled"
    );

    const category = res.records.map((c: any) => ({
      value: c.category_id,
      text: c.name.split("/").pop(),
    }));
    setCategories(category);
  };

  useEffect(() => {
    getGroups();
    getCategories();
  }, []);

  const creator = new SurveyCreator(creatorOptions);

  creator.toolbarItems.push(
    new Action({
      id: "custom-action-button",
      visible: true,
      enabled: !readOnly && !buttonDisabled,
      needSeparator: true,
      css: `form___action-button form__container ${theme.palette.mode}`,
      title: t(buttonLabel),
      action: () => onSubmit(creator),
    })
  );

  if (saveSurvey) {
    creator.toolbarItems.push(
      new Action({
        id: "custom-action-button",
        visible: true,
        enabled: !readOnly && !buttonDisabled,
        needSeparator: false,
        css: `form___action-button form__container ${theme.palette.mode}`,
        title: t("surveys.save_as_template"),
        action: () => onSubmit(creator, true),
      })
    );
  }

  creator.JSON = surveyData || creator.JSON;

  creator.saveSurveyFunc = () => {
    window.localStorage.setItem("saveSurvey", JSON.stringify(creator.JSON));
  };

  return surveyData === null ? (
    <Box
      sx={{
        width: "100%",
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{ height: `calc(100vh - ${appBarHeight}px)` }}
      className={`form__container ${mode}`}
    >
      <SurveyCreatorComponent creator={creator} />
    </Box>
  );
}

CreateSurveyComponent.defaultProps = {
  buttonLabel: "general.create",
  buttonDisabled: false,
  readOnly: false,
  saveSurvey: false,
};
