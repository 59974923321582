import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  SxProps,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { PermissionWrapper } from "@neostella/neostella-ui";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface ContainerActionButtons {
  title: string;
  titleIcon?: any;
  onClick: () => any;
  permissionKeys?: string[];
  variant?: "contained" | "outlined";
  disabled?: boolean;
}

interface ContainerProps {
  children?: JSX.Element;
  title: string;
  onBack?: null | false | (() => void);
  actions?: ContainerActionButtons[];
  tabs?: ContainerTab[];
  content?: JSX.Element[];
  linkTabs?: boolean;
  sx?: SxProps;
}

export interface ContainerTab {
  label: string;
  href?: string;
  disabled?: boolean;
  hide?: boolean;
  icon?: JSX.Element;
  iconPosition?: "top" | "start" | "bottom" | "end";
}

function LinkTab(props: any) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function Container({
  children,
  title,
  onBack,
  actions,
  tabs,
  content,
  linkTabs,
  sx,
}: ContainerProps) {
  tabs = tabs || [];

  const getInitialTab = (pathname: string) => {
    if (linkTabs === false) return 0;

    // eslint-disable-next-line guard-for-in
    for (const tabIndex in tabs) {
      const index = parseInt(tabIndex, 10) || 0;
      const tab = tabs[index];
      if (
        pathname.endsWith(`${tab.href}`) ||
        pathname.endsWith(`${tab.href}/`)
      ) {
        return index;
      }
    }

    return 0;
  };

  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    getInitialTab(location.pathname)
  );

  useEffect(() => {
    setCurrentTab(getInitialTab(location.pathname));
  }, [location, tabs]);

  const renderRightButtons = () =>
    Array.isArray(actions)
      ? actions.map((action) => (
          <PermissionWrapper
            permissionKeys={action.permissionKeys || []}
            type="hide"
            key={action.title}
          >
            <Button
              startIcon={action.titleIcon || null}
              key={action.title}
              variant={action.variant || "contained"}
              disabled={action.disabled === true}
              sx={{
                ml: 2,
                p: 2,
                height: 50,
              }}
              onClick={action.onClick}
            >
              {action.title}
            </Button>
          </PermissionWrapper>
        ))
      : null;

  const renderContent = () =>
    content?.map((c, i) => {
      if (i !== currentTab) return null;
      // eslint-disable-next-line react/no-array-index-key
      return <React.Fragment key={i}>{c}</React.Fragment>;
    });

  return (
    <Box sx={{ p: 2, margin: "0 auto", minWidth: "1000px", ...sx }}>
      <Paper>
        <Box sx={{ mt: 2, pl: 2, display: "flex", alignItems: "center" }}>
          <Box>
            {onBack !== false && (
              <IconButton
                sx={{
                  width: 50,
                  height: 50,
                }}
                onClick={() => {
                  if (typeof onBack === "function") onBack();
                  else history.goBack();
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              sx={{
                wordWrap: "break-word",
                ml: 1,
                lineHeight: 2,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ mr: 2 }}>{renderRightButtons()}</Box>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: 2,
            mb: tabs?.length === 0 ? 2 : 0,
          }}
        />
        {tabs?.length > 0 && (
          <>
            <Box
              sx={{
                pl: 3,
              }}
            >
              <Tabs
                value={currentTab}
                onChange={(e, v) => {
                  if (!linkTabs) {
                    setCurrentTab(v);
                  } else if (Array.isArray(tabs)) {
                    history.push(tabs[v]?.href || "/");
                  }
                }}
                TabIndicatorProps={{
                  style: {
                    opacity: tabs[currentTab]?.hide === true ? 0 : 1,
                  },
                }}
              >
                {tabs?.map((t) =>
                  // eslint-disable-next-line no-nested-ternary
                  linkTabs ? (
                    <LinkTab
                      sx={{
                        opacity: t.hide === true ? 0 : 1,
                      }}
                      disabled={t.hide === true || t.disabled === true}
                      key={t.label}
                      label={t.label}
                      href={t.href}
                      icon={t.icon}
                      iconPosition={t.iconPosition}
                    />
                  ) : (
                    <Tab
                      sx={{
                        opacity: t.hide === true ? 0 : 1,
                      }}
                      disabled={t.hide === true || t.disabled === true}
                      key={t.label}
                      label={t.label}
                      icon={t.icon}
                      iconPosition={t.iconPosition}
                    />
                  )
                )}
              </Tabs>
            </Box>
            <Divider
              sx={{
                mb: 2,
              }}
            />
          </>
        )}
        {Array.isArray(content) && content.length > 0 && renderContent()}
        {children || null}
      </Paper>
    </Box>
  );
}

Container.defaultProps = {
  onBack: null,
  actions: [],
  sx: undefined,
  tabs: [],
  children: null,
  content: null,
  linkTabs: false,
};

export default Container;
