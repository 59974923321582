import { DataGrid, axiosInstance, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface NewReport {
  UUID: string;
  survey_name: string;
  report: Report[];
  result: string;
}

interface Report {
  columns: Columns[];
  rows: [];
}

interface Columns {
  field: string;
  headerName: string;
  flex?: number;
}

export default function NewReportOutput() {
  const [t] = useTranslation();
  const { surveyId } = useParams<{ surveyId: string }>();
  const [reportsData, setReportsData] = useState<Report[] | null>(null);
  const [columns, setColumns] = useState<Columns[]>([]);
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getReportData = async () => {
    setReportsData(null);
    try {
      const { data: res } = await axiosInstance.get<NewReport>(
        `reports/${surveyId}?report_type=2`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        }
      );

      setReportsData(res.report);
      setColumns(
        res.report[0].columns.map((c) => ({
          field: c.field.toString(),
          headerName: c.field,
          flex: 1,
        }))
      );
      setRows(res.report[0].rows);
    } catch (e) {
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  return (
    <DataGrid
      extraProps={{ getRowId: () => Math.random() }}
      sx={{ minHeight: 400, m: 4 }}
      disableSelectionOnClick
      loading={reportsData === null}
      quickFilter
      columns={columns || []}
      rows={rows || []}
    />
  );
}
