import { useEffect, useState } from "react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";

import { axiosInstance, errorHandler } from "@neostella/neostella-ui";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { SurveyCreator } from "survey-creator-react";
import usePresignedUrls from "../../../../hooks/usePresignedUrls";
import { SurveysPostResponse } from "../../Accounts/Surveys/AccountSurveysCreateFromScratch";
import { SurveyData } from "../../Accounts/Surveys/AccountSurveysCreateFromTemplate";
import CreateSurveyComponent, {
  PutPayload,
} from "../../Accounts/Surveys/CreateSurveyComponent";

export default function SurveyTemplatesEdit() {
  const { id }: any = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const history = useHistory();
  const [surveyDataL, setSurveyDataL] = useState<SurveyData | null>(null);
  const { getPresignedUrl, fetchSurveyData, saveSurveyData, isSurveyValid } =
    usePresignedUrls();

  const getSurveyData = async () => {
    const presignedUrlGET = await getPresignedUrl(
      "get_object",
      `surveys/${id}.json`
    );

    const surveyDataJson = await fetchSurveyData<SurveyData>(
      presignedUrlGET.presigned_url.url
    );
    setSurveyDataL(surveyDataJson);
  };
  useEffect(() => {
    getSurveyData();
  }, []);

  const editSurvey = async (creator: SurveyCreator) => {
    setIsSubmitting(true);
    const surveyJSON = creator.JSON;
    setSurveyDataL(surveyJSON);
    const putPayload: PutPayload = {
      survey_id: id,
      name: surveyJSON.title,
      // survey_data: surveyJSON,
      is_active: true,
      status: "draft",
    };
    try {
      if (isSurveyValid(surveyJSON)) {
        const { data } = await axiosInstance.put<SurveysPostResponse>(
          "surveys",
          putPayload
        );
        const presignedUrlPUT = await getPresignedUrl(
          "put_object",
          `surveys/${data.survey_id}.json`
        );

        await saveSurveyData(surveyJSON, data.survey_id, presignedUrlPUT);
        setTimeout(() => {
          enqueueSnackbar(t("surveys.survey_template_updated"), {
            variant: "success",
          });
          history.push("/admin/survey_templates");
        }, 200);
        setIsSubmitting(false);
      } else {
        enqueueSnackbar(
          t("errors.verify_all_questions_have_a_valid_category_and_group"),
          {
            variant: "error",
          }
        );
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      errorHandler(e, enqueueSnackbar, t);
    }
  };

  return (
    <CreateSurveyComponent
      onSubmit={editSurvey}
      surveyData={surveyDataL}
      buttonLabel="general.update"
      buttonDisabled={isSubmitting}
    />
  );
}
