import { Container } from "@neostella/neostella-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NewReportOutput from "./NewReportOutput";
import OldReportOutput from "./OldReportOutput";

export default function AccountSurveyReport() {
  const { surveyId, id } = useParams<{ surveyId: string; id: string }>();
  const [t] = useTranslation();

  return (
    <Container
      title={t("general.reporting")}
      linkTabs
      tabs={[
        {
          label: "Old Report",
          href: `/accounts/${id}/survey/${surveyId}/report/1`,
        },
        {
          label: "New Report",
          href: `/accounts/${id}/survey/${surveyId}/report/2`,
        },
      ]}
      content={[<OldReportOutput />, <NewReportOutput />]}
    />
  );
}
